import { createAction, props } from '@ngrx/store';
import { ReasoningFaultKind } from '@workbench/business-logic/enums/reasoning-fault-kind.enum';
import { CausalAnalysisPropagationPath } from '@workbench/common/models/causal-reasoning-path.model';
import { ExtendedSyntaxIssues } from '@workbench/multilevel-flow-modeling/syntax-issues-section/extended-syntax-issue.model';

export const copyToClipboard = createAction('[Reasoning] Copy to Clipboard');
export const fetchValidationResultFault = createAction(
  '[Reasoning/API] Fetch Validation Result Fault',
  props<{ message: string }>(),
);
export const fetchValidationResultSuccess = createAction(
  '[Reasoning/API] Fetch Validation Result Success',
  props<{ result: ExtendedSyntaxIssues }>(),
);
export const resetReasoning = createAction('[Reasoning] Reset');
export const runReasoning = createAction(
  '[Reasoning/API] Run Reasoning',
  props<{ extendedModel: unknown }>(),
);
export const runReasoningFault = createAction(
  '[Reasoning/API] Run Reasoning Fault',
  props<{ message: string }>(),
);
export const setFaultCase = createAction(
  '[Reasoning] Set Fault Case',
  props<{ functionId: string; fault: ReasoningFaultKind }>(),
);
export const setReasoningDisplaySection = createAction(
  '[Reasoning] Set Reasoning Display Section',
  props<{ section: 'causes' | 'consequences' }>(),
);
export const setReasoningResults = createAction(
  '[Reasoning] Set Reasoning Results',
  props<{
    causes: CausalAnalysisPropagationPath[];
    consequences: CausalAnalysisPropagationPath[];
  }>(),
);
export const setSelectedPathIndex = createAction(
  '[Reasoning] Set Selected Path Index',
  props<{ index: number }>(),
);
export const validate = createAction('[Reasoning] Validate', props<{ extendedModel: unknown }>());
export const validateBeforeReasoning = createAction(
  '[Reasoning] Validate Before Reasoning',
  props<{ extendedModel: unknown }>(),
);
