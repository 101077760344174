/* eslint-disable @typescript-eslint/naming-convention */
import { createActionGroup, props } from '@ngrx/store';
import { ModelFile } from '@workbench/common/models/model-file.model';

export const portalActions = createActionGroup({
  source: 'Portal/API',
  events: {
    fetchModel: props<{ id: string; version?: string }>(),
    fetchModelData: props<{ guid: string; version?: string }>(),
    fetchModelDataFault: props<{ message: string }>(),
    fetchModelDataSuccess: props<{ model: unknown[] }>(),
    fetchModelFile: props<{ id: string; version?: string }>(),
    fetchModelFileFault: props<{ message: string }>(),
    fetchModelFileSuccess: props<{ file: ModelFile; version?: string }>(),
    fetchModelVersions: props<{ id: string }>(),
    fetchModelVersionsFault: props<{ message: string }>(),
    fetchModelVersionsSuccess: props<{
      id: string;
      versions: { version: string; fileTag: { tag: string; comment: string } | null }[];
    }>(),
    saveModelData: props<{ data: unknown }>(),
    saveModelDataFault: props<{ message: string }>(),
    saveModelDataSuccess: props<{ modelFile: ModelFile }>(),
  },
});
