import { MainFunction } from '@workbench/business-logic/models/main-function.model';
import { getConcept } from '@workbench/common/utils/mx-graph-util';
import { mxgraph } from '@workbench/dts/mxg';
import { and, equal, it, not, Predicate } from '../../common/utils/logical-utility';
import { findSpecialConnectionEnd, isStructure } from './mfm-core';

/**
 * Process a set of source, edge, and target item:
 * - check for the special connection
 * - create/remove Main Function
 * - return the set of items that should be connected
 *
 * @param source Source cell
 * @param edge Edge cell
 * @param target Target cell
 * @returns a array of three item cells (source, edge, target)
 */
export function connectionProcessor(
  source: mxgraph.mxCell,
  edge: mxgraph.mxCell,
  target: mxgraph.mxCell,
  sourceParent: mxgraph.mxCell,
  targetParent: mxgraph.mxCell,
): [mxgraph.mxCell, mxgraph.mxCell, mxgraph.mxCell] {
  const haveStructureInCommon: Predicate = and(
    isStructure(getConcept(sourceParent)),
    isStructure(getConcept(targetParent)),
    equal(
      () => sourceParent.getId(),
      () => targetParent.getId(),
    ),
  );

  if (it(not(haveStructureInCommon))) {
    // Check do we have a special connection and get what end should be moved to the structure.
    // 'none' - a general connection
    // 'source' or 'target' - the end that should be shifted to the structure
    const special = findSpecialConnectionEnd(
      getConcept(source),
      getConcept(edge),
      getConcept(target),
      getConcept(sourceParent),
      getConcept(targetParent),
    );

    if (special !== 'none') {
      edge.mfm.mainFunction = new MainFunction();
      if (special === 'source') {
        edge.mfm.mainFunction.sourceId = source.getId().toString();

        return [sourceParent, edge, target];
      }
      if (special === 'target') {
        edge.mfm.mainFunction.targetId = target.getId().toString();

        return [source, edge, targetParent];
      }
    }
  }

  edge.mfm.mainFunction = null;

  return [source, edge, target];
}
