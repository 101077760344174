import {
  isOfflineProcessVariable,
  isVirtualProcessVariable,
  processVariableValue,
} from '@workbench/common/types/process-variable-value.type';
import { it } from '@workbench/common/utils/logical-utility';
import { mxConstants, mxEllipse, mxgraph } from '@workbench/dts/mxg';
import {
  styleBorderColor,
  styleBorderWidth,
  styleExposed,
  styleGlowColor,
  styleHighlightColor,
  styleOutlineColor,
  styleProcessVariable,
} from '../extensions/custom-style';
import { processVariableStyleColor } from './colors';
import { paintShapeGlow } from './shape-glow';

export class SourceShape extends mxEllipse {
  public readonly constraints: mxgraph.mxConnectionConstraint[] = [];

  public paintVertexShape(
    c: mxgraph.mxSvgCanvas2D,
    x: number,
    y: number,
    w: number,
    h: number,
  ): void {
    const w2 = w / 2;
    const h2 = h / 2;
    const xw = x + w;
    const yh = y + h;

    const exposed = this.style[styleExposed()] ?? 0;
    const glowColor = this.style[styleGlowColor()];
    const outlineColor = this.style[styleOutlineColor()];
    const processVariable = processVariableValue(this.style[styleProcessVariable()]);
    const processVariableColor = processVariableStyleColor(processVariable);

    if (glowColor) {
      paintShapeGlow(c, x, y, w, h, glowColor);
    }
    if (outlineColor) {
      c.setStrokeAlpha(1);
      c.setStrokeColor(outlineColor);
      c.setStrokeWidth(16);
      c.setFillAlpha(1);
      c.setFillColor(outlineColor);

      if (exposed === 0) {
        if (outlineColor) {
          super.paintVertexShape(c, x, y, w, h);
        }
      }
      if (exposed === 1) {
        c.begin();
        c.moveTo(x + w2, y);
        c.curveTo(x + w2, y, xw, y, xw, y + h2);
        c.curveTo(xw, y + h2, xw, yh, x + w2, yh);
        c.close(x + w2, y, null, null, null, null);
        c.end();
        c.fillAndStroke();
      }
    }

    const d = Math.min(0.3 * w, 0.3 * h);
    const r = d / 2;

    c.setFillAlpha(1);
    // prettier-ignore
    c.setFillColor(this.style[styleHighlightColor()] ?? processVariableColor ?? this.style[mxConstants.STYLE_FILLCOLOR],);
    c.setStrokeAlpha(1);
    c.setStrokeColor(this.style[styleBorderColor()] ?? this.style[mxConstants.STYLE_STROKECOLOR]);
    c.setStrokeWidth(this.style[styleBorderWidth()] ?? this.style[mxConstants.STYLE_STROKEWIDTH]);

    if (exposed === 1) {
      c.begin();
      c.moveTo(x + w2, y);
      c.curveTo(x + w2, y, xw, y, xw, y + h2);
      c.curveTo(xw, y + h2, xw, yh, x + w2, yh);
      c.close(x + w2, y, null, null, null, null);
      c.fill();

      if (it(isVirtualProcessVariable(processVariable))) {
        const d2 = 12;

        c.setFillColor(processVariableColor);
        c.ellipse(x + w - d2 / 2, y + h - d2 / 2, d2, d2);
        c.fillAndStroke();
      }
      if (it(isOfflineProcessVariable(processVariable))) {
        const d2 = 20;

        c.setFillColor('#FFFFFF');
        c.begin();
        c.moveTo(x + w / 2, y + h / 2 - d2 / 2);
        c.curveTo(
          x + w / 2,
          y + h / 2 - d2 / 2,
          x + w / 2 + d2 / 2,
          y + h / 2 - d2 / 2,
          x + w / 2 + d2 / 2,
          y + h / 2,
        );
        c.curveTo(
          x + w / 2 + d2 / 2,
          y + h / 2,
          x + w / 2 + d2 / 2,
          y + h / 2 + d2 / 2,
          x + w / 2,
          y + h / 2 + d2 / 2,
        );
        c.close(x + w / 2, y + h / 2 - r / 2, null, null, null, null);
        c.fill();
      }

      c.setFillColor(this.style[mxConstants.STYLE_STROKECOLOR]);
      c.setStrokeColor(this.style[mxConstants.STYLE_STROKECOLOR]);
      c.begin();
      c.moveTo(x + w2, y + (h2 - r));
      c.curveTo(x + w2, y + (h2 - r), x + (w2 + r), y + (h2 - r), x + (w2 + r), y + h2);
      c.curveTo(x + (w2 + r), y + h2, x + (w2 + r), y + (h2 + r), x + w2, y + (h2 + r));
      c.close(x + w2, y + (h2 - r), null, null, null, null);
      c.fill();

      // A border around the shape (half of a circle)
      c.begin();
      c.moveTo(x + w2, y);
      c.curveTo(x + w2, y, xw, y, xw, y + h2);
      c.curveTo(xw, y + h2, xw, yh, x + w2, yh);
      c.close(x + w2, y, null, null, null, null);
      c.stroke();
    }
    if (exposed === 0) {
      super.paintVertexShape(c, x, y, w, h);

      if (it(isVirtualProcessVariable(processVariable))) {
        const d2 = 12;

        c.setFillColor(processVariableColor);
        c.ellipse(x + w - d2 / 2, y + h - d2 / 2, d2, d2);
        c.fillAndStroke();
      }
      if (it(isOfflineProcessVariable(processVariable))) {
        const d2 = 20;

        c.setFillColor('#FFFFFF');
        c.ellipse(x + w / 2 - d2 / 2, y + h / 2 - d2 / 2, d2, d2);
        c.fill();
      }

      c.setFillColor(this.style[mxConstants.STYLE_STROKECOLOR]);
      c.ellipse(x + (w - d) / 2, y + (h - d) / 2, d, d);
      c.fill();
    }
  }

  public getTextRotation(): number {
    // text label is always above model, so text rotation is prevented
    return 0;
  }
}
