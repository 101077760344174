import { getNotEmpty } from '@workbench/common/utils/string-util';
import { mxgraph } from '@workbench/dts/mxg';
import { styleHighlightColor } from '../extensions/custom-style';
import { ReasoningFaultBaseShape } from './reasoning-fault-base-shape';

export class ReasoningFaultKindHighShape extends ReasoningFaultBaseShape {
  protected drawStateIcon(
    c: mxgraph.mxSvgCanvas2D,
    x: number,
    y: number,
    w: number,
    h: number,
  ): void {
    c.setStrokeWidth(3);

    c.translate(x, y);

    c.begin();
    c.moveTo(0.176 * w, 0.667 * h);
    c.lineTo(0.353 * w, 0.48 * h);
    c.lineTo(0.5 * w, 0.627 * h);
    c.lineTo(0.77 * w, 0.34 * h);
    c.moveTo(0.5 * w, 0.35 * h);
    c.lineTo(0.8 * w, 0.35 * h);
    c.moveTo(0.76 * w, 0.59 * h);
    c.lineTo(0.76 * w, 0.31 * h);
    c.stroke();
  }

  protected getFillColor(): string {
    return getNotEmpty(
      this.style[styleHighlightColor()],
      this.tryGetActuatorColor(),
      this.tryGetProcessVariableColor(),
    );
  }
}
