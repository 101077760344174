import { GraphOperationMode } from '@workbench/business-logic/graph-operation-mode';
import { ResourceConcept } from '@workbench/common/enums/resource-concept.enum';
import { ModelSyntaxError } from '@workbench/common/models/model-syntax-error.model';
import { it } from '@workbench/common/utils/logical-utility';
import { capitalize, formatString } from '@workbench/common/utils/string-util';
import { mxgraph } from '@workbench/dts/mxg';
import { ModelBuilderMode } from '@workbench/model-project/model-builder-mode.enum';
import { isRelation } from '@workbench/multilevel-flow-modeling/core/mfm-core';
import { getConceptFullName } from '@workbench/multilevel-flow-modeling/core/mfm-descriptor';
import {
  ExtendedSyntaxIssue,
  ExtendedSyntaxIssues,
} from '@workbench/multilevel-flow-modeling/syntax-issues-section/extended-syntax-issue.model';
import { GraphRepository } from '@workbench/mx-graph/graph-repository';
import { MfmConceptConfiguration } from '@workbench/state/model-builder/mfm-model.model';
import { ModelBuilderContextMenuService } from '../model-builder/model-builder-context-menu-service';
import { UiRepository } from '../ui-repository';

/**
 * The service is used to manage the status of the Reasoning and display the results of the Reasoning.
 */
export class ReasoningService {
  constructor(
    private readonly graphMode: GraphOperationMode,
    private readonly graphRepository: GraphRepository,
    private readonly modelBuilderContextMenuService: ModelBuilderContextMenuService,
    private readonly uiRepository: UiRepository,
  ) {}

  // TODO: This is not the responsibility of ReasoningService.
  // Should be moved out.
  /**
   * Handles cases of right-clicking on the model
   *
   * @param cell Cell of Model that was clicked
   * @param event Event of click
   */
  public onCellRightMouseClick(cell: mxgraph.mxCell, event: MouseEvent): void {
    const model = this.graphRepository.getUpdatedModelByCell(cell);

    if (this.graphMode.isEditMode()) {
      this.modelBuilderContextMenuService
        .generateMenuData(model, ModelBuilderMode.Edit)
        .subscribe(data => {
          this.uiRepository.showContextMenu(event, data);
        });
    }

    if (this.graphMode.isReasoningMode()) {
      this.modelBuilderContextMenuService
        .generateMenuData(model, ModelBuilderMode.Reasoning)
        .subscribe(data => {
          this.uiRepository.showContextMenu(event, data);
        });
    }
  }
}

export const syntaxErrorsFormatter = (
  errors: ModelSyntaxError[],
  mfmModel: Map<string, MfmConceptConfiguration>,
  fullModel: Map<string, { id: string; concept: ResourceConcept }>,
): ExtendedSyntaxIssues => {
  const extendedErrors: ExtendedSyntaxIssues = errors.map(error => {
    const labels: string[] = error.ids.map(id => {
      const item: { id: string; concept: ResourceConcept; label: string; internal?: true } =
        mfmModel.get(id) ?? {
          ...fullModel.get(id),
          label: id,
          internal: true,
        };

      if (item.internal === true) {
        return item.id;
      }
      if (it(isRelation(() => item.concept))) {
        return getConceptFullName(item.concept);
      }
      if ((item.label ?? '').length > 0) {
        return item.label;
      }

      return getConceptFullName(item.concept);
    });

    const originalMessage: string = error.message;
    const message = capitalize(formatString(error.message, ...labels));

    // Improved version
    const messageItems = [];
    const msgParts = originalMessage.split(/\{.\}/);

    msgParts.forEach((part: string, index: number) => {
      if (part.length > 0) {
        messageItems.push({ value: part, isElem: false });
      }

      if (labels.length > index) {
        messageItems.push({ value: labels[index], isElem: true, id: error.ids[index] });
      }
    });

    return { ...error, message, messageItems } as ExtendedSyntaxIssue;
  });

  return extendedErrors;
};
