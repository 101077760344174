import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { MfmConceptConfiguration } from './mfm-model.model';

export const importActions = createActionGroup({
  source: 'Model Import',
  events: {
    completed: emptyProps(),
    createGraph: emptyProps(),
    createGraphCompleted: emptyProps(),
    createModel: props<{ data: unknown }>(),
    createModelCompleted: props<{ model: MfmConceptConfiguration[] }>(),
    importCraLabels: props<{ data: unknown }>(),
    importSubModels: props<{ data: unknown }>(),
    start: emptyProps(),
  },
});
