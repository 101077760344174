import { ResourceConcept } from '@workbench/common/enums/resource-concept.enum';
import { exist, it } from '@workbench/common/utils/logical-utility';
import { mxCellRenderer, mxConstants, mxgraph } from '@workbench/dts/mxg';
import { AndShape } from './and-shape';
import { BalanceShape } from './balance-shape';
import { BarrierShape } from './barrier-shape';
import { ControlFlowStructureShape } from './control-flow-structure-shape';
import { DestroyShape } from './destroy-shape';
import { EnergyFlowStructureShape } from './energy-flow-structure-shape';
import { HazardShape } from './hazard-shape';
import { MainEquipmentShape } from './main-equipment-shape';
import { MaintainShape } from './maintain-shape';
import { MassFlowStructureShape } from './mass-flow-structure-shape';
import { ProduceShape } from './produce-shape';
import { ReasoningFaultKindBreachShape } from './reasoning-fault-kind-breach-shape';
import { ReasoningFaultKindControlHighShape } from './reasoning-fault-kind-control-high-shape';
import { ReasoningFaultKindControlLowShape } from './reasoning-fault-kind-control-low-shape';
import { ReasoningFaultKindFalseShape } from './reasoning-fault-kind-false-shape';
import { ReasoningFaultKindHighShape } from './reasoning-fault-kind-high-shape';
import { ReasoningFaultKindLowShape } from './reasoning-fault-kind-low-shape';
import { ReasoningFaultKindNormalShape } from './reasoning-fault-kind-normal-shape';
import { ReasoningFaultKindTrueShape } from './reasoning-fault-kind-true-shape';
import { SinkShape } from './sink-shape';
import { SourceShape } from './source-shape';
import { StorageShape } from './storage-shape';
import { SubModelPortShape } from './sub-model-port-shape';
import { SubModelShape } from './sub-model-shape';
import { SuppressShape } from './suppress-shape';
import { SystemShape } from './system-shape';
import { TargetShape } from './target-shape';
import { TextShape } from './text-shape';
import { TransportShape } from './transport-shape';
import { VotingOrShape } from './voting-or-shape';

export const registerShapes = (): void => {
  const register: (key: string, shape: mxgraph.mxShape) => void = (
    mxCellRenderer.prototype.constructor as typeof mxgraph.mxCellRenderer
  ).registerShape;

  register(getConceptShape(ResourceConcept.And), AndShape);
  register(getConceptShape(ResourceConcept.Bal), BalanceShape);
  register(getConceptShape(ResourceConcept.Bar), BarrierShape);
  register(getConceptShape(ResourceConcept.Cfs), ControlFlowStructureShape);
  register(getConceptShape(ResourceConcept.Dco), DestroyShape);
  register(getConceptShape(ResourceConcept.Efs), EnergyFlowStructureShape);
  register(getConceptShape(ResourceConcept.Haz), HazardShape);
  register(getConceptShape(ResourceConcept.Mco), MaintainShape);
  register(getConceptShape(ResourceConcept.Meq), MainEquipmentShape);
  register(getConceptShape(ResourceConcept.Mfs), MassFlowStructureShape);
  register(getConceptShape(ResourceConcept.Pco), ProduceShape);
  register(getConceptShape(ResourceConcept.Sin), SinkShape);
  register(getConceptShape(ResourceConcept.Sou), SourceShape);
  register(getConceptShape(ResourceConcept.Sto), StorageShape);
  register(getConceptShape(ResourceConcept.Sub), SubModelShape);
  register(getConceptShape(ResourceConcept.Sup), SuppressShape);
  register(getConceptShape(ResourceConcept.Sys), SystemShape);
  register(getConceptShape(ResourceConcept.Tar), TargetShape);
  register(getConceptShape(ResourceConcept.Tra), TransportShape);
  register(getConceptShape(ResourceConcept.Txt), TextShape);
  register(getConceptShape(ResourceConcept.Vor), VotingOrShape);
  // workaround to support legacy shapes
  register('andShape', AndShape);
  register('vorShape', VotingOrShape);
  // Shapes for Reasoning Fault Kinds
  register('reasoning-fault-kind-breach', ReasoningFaultKindBreachShape);
  register('reasoning-fault-kind-control-high', ReasoningFaultKindControlHighShape);
  register('reasoning-fault-kind-control-low', ReasoningFaultKindControlLowShape);
  register('reasoning-fault-kind-false', ReasoningFaultKindFalseShape);
  register('reasoning-fault-kind-high', ReasoningFaultKindHighShape);
  register('reasoning-fault-kind-low', ReasoningFaultKindLowShape);
  register('reasoning-fault-kind-normal', ReasoningFaultKindNormalShape);
  register('reasoning-fault-kind-true', ReasoningFaultKindTrueShape);
  // Shape for the Sub-model port
  register('sub-model-port', SubModelPortShape);
};

export const getConceptShape = (concept: ResourceConcept): string | never =>
  getConceptCellSettings(concept)[0];
export const getConceptPerimeter = (concept: ResourceConcept): string | never =>
  getConceptCellSettings(concept)[1];
export const getDefaultConceptStyles = (
  concept: ResourceConcept,
  styleName?: string,
): string | never =>
  it(exist(() => styleName))
    ? getConceptCellSettings(concept)[2][styleName]
    : Object.keys(getConceptCellSettings(concept)[2])
        .map(key => `${key}=${getDefaultConceptStyles(concept, key)}`)
        .join(';');

// Mapping from MFM concept to the shape name, perimeter name and default style settings
const getConceptCellSettings = (
  concept: ResourceConcept,
): [string, string, Record<string, string>] | never => {
  switch (concept) {
    case ResourceConcept.And:
      return ['and_shape', mxConstants.PERIMETER_RECTANGLE, {}];
    case ResourceConcept.Bal:
      return ['balShape', mxConstants.PERIMETER_HEXAGON, {}];
    case ResourceConcept.Bar:
      return ['barShape', mxConstants.PERIMETER_RHOMBUS, {}];
    case ResourceConcept.Cfs:
      return ['control_flow_structure_shape', mxConstants.PERIMETER_RECTANGLE, {}];
    case ResourceConcept.Dco:
      return ['dcoShape', mxConstants.PERIMETER_RECTANGLE, {}];
    case ResourceConcept.Efs:
      return ['energy_flow_structure_shape', mxConstants.PERIMETER_RECTANGLE, {}];
    case ResourceConcept.Haz:
      return [
        'hazShape',
        'customEllipsePerimeter',
        {
          [mxConstants.STYLE_FILLCOLOR]: '#000000',
          [mxConstants.STYLE_STROKECOLOR]: '#000000',
        },
      ];
    case ResourceConcept.Mco:
      return ['mcoShape', mxConstants.PERIMETER_RECTANGLE, {}];
    case ResourceConcept.Meq:
      return ['main_equipment_shape', mxConstants.PERIMETER_RECTANGLE, {}];
    case ResourceConcept.Mfs:
      return ['mass_flow_structure_shape', mxConstants.PERIMETER_RECTANGLE, {}];
    case ResourceConcept.Pco:
      return ['pcoShape', mxConstants.PERIMETER_RECTANGLE, {}];
    case ResourceConcept.Sin:
      return ['sinShape', mxConstants.PERIMETER_ELLIPSE, {}];
    case ResourceConcept.Sou:
      return ['souShape', mxConstants.PERIMETER_ELLIPSE, {}];
    case ResourceConcept.Sto:
      return ['stoShape', mxConstants.PERIMETER_HEXAGON, {}];
    case ResourceConcept.Sub:
      return ['sub_model_shape', mxConstants.PERIMETER_RECTANGLE, {}];
    case ResourceConcept.Sup:
      return ['supShape', mxConstants.PERIMETER_RECTANGLE, {}];
    case ResourceConcept.Sys:
      return ['system_shape', mxConstants.PERIMETER_RECTANGLE, {}];
    case ResourceConcept.Tar:
      return ['tarShape', 'customEllipsePerimeter', { [mxConstants.STYLE_FILLCOLOR]: '#FFFFFF' }];
    case ResourceConcept.Tra:
      return ['traShape', mxConstants.PERIMETER_RHOMBUS, {}];
    case ResourceConcept.Txt:
      return ['text_shape', mxConstants.PERIMETER_RECTANGLE, {}];
    case ResourceConcept.Vor:
      return ['voting_or_shape', mxConstants.PERIMETER_RECTANGLE, {}];
    default:
      throw new Error(`The settings for '${concept}' concept is not defined`);
  }
};
