import { mxConstants, mxgraph, mxRectangleShape } from '@workbench/dts/mxg';

export class TextShape extends mxRectangleShape {
  public readonly constraints: mxgraph.mxConnectionConstraint[] = [];

  public paintVertexShape(
    c: mxgraph.mxSvgCanvas2D,
    x: number,
    y: number,
    w: number,
    h: number,
  ): void {
    c.translate(x, y);
    c.setStrokeColor('#000000');
    c.setStrokeWidth(2);
    c.roundrect(0, 0, w, h, 5, 5);
    c.stroke();
    c.setFontSize(Math.min(w, h));
    c.setFontFamily('Segoe UI');
    // prettier-ignore
    c.text(w / 2, h / 3, 0, 0, 'T', mxConstants.ALIGN_CENTER, mxConstants.ALIGN_MIDDLE, null, null, null, null, 0, null);
  }

  public getTextRotation(): number {
    // text label is always above model, so text rotation is prevented
    return 0;
  }
}
