import {
  isProcessVariable,
  ProcessVariableValue,
} from '@workbench/common/types/process-variable-value.type';
import { it } from '@workbench/common/utils/logical-utility';

export const processVariableStyleColor = (value: ProcessVariableValue): string | undefined => {
  if (it(isProcessVariable(value))) {
    return processVariableColor();
  }
};

export const actuatorColor = (): '#FF9373' => '#FF9373';
export const processVariableColor = (): '#FFD060' => '#FFD060';
