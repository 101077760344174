/**
 * Calculate the start point, the endpoint of the arc, and the control point,
 * on a circle with a center in 0,0. So the arc can be created using quadratic bezier curve
 *
 * @param r radius
 * @param a1 start angle in radians
 * @param a2 end angle in radians
 */
export function arc(r: number, a1: number, a2: number): Point[] {
  const a0 = (a2 - a1) / 2;

  return [
    rotate2D([r, 0], [0, 0], a1),
    rotate2D([r / Math.cos(a0), 0], [0, 0], a1 + a0),
    rotate2D([r, 0], [0, 0], a2),
  ].map(([x, y]) => ({ x, y }));
}

/**
 * Rotate provided point `p` clockwise on an angle `a` around the point `p0`
 *
 * @param p array with coordinates of a point [x, y]
 * @param p0 array with coordinates of a point [x, y]
 * @param a angle in radians
 */
export function rotate2D(p: [number, number], p0: [number, number], a: number): [number, number] {
  return [
    (p[0] - p0[0]) * Math.cos(a) - (p[1] - p0[1]) * Math.sin(a),
    (p[0] - p0[0]) * Math.sin(a) + (p[1] - p0[1]) * Math.cos(a),
  ];
}

/**
 * Converts degrees to radians
 *
 * @param deg degrees
 */
export function deg2rad(deg: number): number {
  return (Math.PI / 180) * deg;
}

/**
 * Shifts point by the given value
 *
 * @param p Point to be moved
 * @param x Shift by abscissa
 * @param y shift by ordinate
 */
export function translate(p: Point, x: number, y: number): Point {
  return {
    x: p.x + x,
    y: p.y + y,
  };
}

/**
 * Convert from Polar Coordinates to Cartesian Coordinates
 *
 * @param centerX Circle center X coordinate
 * @param centerY Circle center Y coordinate
 * @param radius Circle radius
 * @param angleInDegrees Angle in degrees
 * @returns
 */
export function polarToCartesian(
  centerX: number,
  centerY: number,
  radius: number,
  angleInDegrees: number,
): { x: number; y: number } {
  const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;

  return {
    x: centerX + radius * Math.cos(angleInRadians),
    y: centerY + radius * Math.sin(angleInRadians),
  };
}

interface Point {
  x: number;
  y: number;
}
