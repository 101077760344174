import { ResourceConcept } from '@workbench/common/enums/resource-concept.enum';

export function getConceptFullName(concept: ResourceConcept): string | undefined {
  return map.get(concept);
}

const map = new Map<ResourceConcept, string>([
  [ResourceConcept.Ac, 'Actuate'],
  [ResourceConcept.And, 'And'],
  [ResourceConcept.Bal, 'Balance'],
  [ResourceConcept.Bar, 'Barrier'],
  [ResourceConcept.BarD, 'Barrier'],
  [ResourceConcept.BarU, 'Barrier'],
  [ResourceConcept.Cfs, 'Control Flow'],
  [ResourceConcept.Dco, 'Destroy'],
  [ResourceConcept.De, 'Destroy'],
  [ResourceConcept.Di, 'Disable'],
  [ResourceConcept.Efs, 'Energy Flow'],
  [ResourceConcept.En, 'Enable'],
  [ResourceConcept.Haz, 'Hazard'],
  [ResourceConcept.In, 'Influencer'],
  [ResourceConcept.Ip, 'Inverse Producer-Product'],
  [ResourceConcept.Ma, 'Maintain'],
  [ResourceConcept.Mco, 'Maintain'],
  [ResourceConcept.Me, 'Mediate'],
  [ResourceConcept.Meq, 'Main Equipment'],
  [ResourceConcept.Mfs, 'Mass Flow'],
  [ResourceConcept.Pa, 'Participant'],
  [ResourceConcept.Pco, 'Produce'],
  [ResourceConcept.Pp, 'Producer-Product'],
  [ResourceConcept.Pr, 'Produce'],
  [ResourceConcept.Sha, 'Sharing Relation'],
  [ResourceConcept.Sin, 'Sink'],
  [ResourceConcept.Sou, 'Source'],
  [ResourceConcept.Sto, 'Storage'],
  [ResourceConcept.Su, 'Suppress'],
  [ResourceConcept.Sub, 'Sub-model'],
  [ResourceConcept.Sup, 'Suppress'],
  [ResourceConcept.Sys, 'System'],
  [ResourceConcept.Tar, 'Target'],
  [ResourceConcept.Tra, 'Transport'],
  [ResourceConcept.TraD, 'Transport'],
  [ResourceConcept.TraU, 'Transport'],
  [ResourceConcept.Txt, 'Note'],
  [ResourceConcept.Vor, 'Voting OR'],
]);
