import { getNotEmpty } from '@workbench/common/utils/string-util';
import { mxgraph } from '@workbench/dts/mxg';
import { styleHighlightColor } from '../extensions/custom-style';
import { ReasoningFaultBaseShape } from './reasoning-fault-base-shape';

export class ReasoningFaultKindTrueShape extends ReasoningFaultBaseShape {
  protected drawStateIcon(
    c: mxgraph.mxSvgCanvas2D,
    x: number,
    y: number,
    w: number,
    h: number,
  ): void {
    const innerCircleD = Math.min(0.18 * w, 0.18 * h);

    c.setFillColor('#000000');

    c.begin();
    c.ellipse(
      x + (0.5 * w - innerCircleD / 2),
      y + (0.78 * h - innerCircleD / 2),
      innerCircleD,
      innerCircleD,
    );
    c.fill();
    c.end();
    c.translate(x, y);

    c.begin();
    c.moveTo(0.41 * w, 0.18 * h);
    c.lineTo(0.59 * w, 0.18 * h);
    c.lineTo(0.54 * w, 0.6 * h);
    c.lineTo(0.46 * w, 0.6 * h);
    c.lineTo(0.41 * w, 0.17 * h);
    c.fill();
    c.end();
  }

  protected getFillColor(): string {
    return getNotEmpty(
      this.style[styleHighlightColor()],
      this.tryGetActuatorColor(),
      this.tryGetProcessVariableColor(),
    );
  }
}
