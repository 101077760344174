import { MfmModel } from '@workbench/business-logic/models/mfm.model';
import { mxgraph } from '@workbench/dts/mxg';
import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('CopyItem')
export class CopyItem {
  @JsonProperty('style', String)
  public style: string = null;

  @JsonProperty('mfm', MfmModel)
  public mfm: MfmModel = null;

  @JsonProperty('geometry')
  public geometry: mxgraph.mxGeometry = null;
}
