import { actuatorKind, ActuatorKindStyle } from '@workbench/common/types/actuator-kind.type';
import {
  isOfflineProcessVariable,
  isVirtualProcessVariable,
  processVariableValue,
} from '@workbench/common/types/process-variable-value.type';
import { it } from '@workbench/common/utils/logical-utility';
import {
  mxConnectionConstraint,
  mxConstants,
  mxgraph,
  mxPoint,
  mxRhombus,
} from '@workbench/dts/mxg';
import {
  styleActuator,
  styleBorderColor,
  styleBorderWidth,
  styleGlowColor,
  styleHighlightColor,
  styleOutlineColor,
  styleProcessVariable,
  styleSensorState,
} from '../extensions/custom-style';
import { actuatorColor, processVariableStyleColor } from './colors';
import { paintShapeGlow } from './shape-glow';

export class BarrierShape extends mxRhombus {
  public readonly constraints: mxgraph.mxConnectionConstraint[] = [
    new mxConnectionConstraint(new mxPoint(0, 0.5), true, 'in'),
    new mxConnectionConstraint(new mxPoint(1, 0.5), true, 'out'),
  ];

  public paintVertexShape(
    c: mxgraph.mxSvgCanvas2D,
    x: number,
    y: number,
    w: number,
    h: number,
  ): void {
    const glowColor = this.style[styleGlowColor()];
    const outlineColor = this.style[styleOutlineColor()];
    const actuatorValue = actuatorKind(this.style[styleActuator()]);
    const processVariable = processVariableValue(this.style[styleProcessVariable()]);
    const processVariableColor = processVariableStyleColor(processVariable);
    const sensorState = this.style[styleSensorState()] ?? '';

    if (glowColor) {
      paintShapeGlow(c, x, y, w, h, glowColor);
    }

    if (outlineColor) {
      const d = 12;

      c.setFillColor(outlineColor);
      c.setFillAlpha(1);
      c.setStrokeAlpha(0);
      super.paintVertexShape(c, x - d, y - d, w + 2 * d, h + 2 * d);
    }

    if (sensorState !== '') {
      const rotation = this.style[mxConstants.STYLE_ROTATION] ?? 0;
      // Always keep the text at the bottom of the shape
      const tp = c.rotatePoint(x + w / 2, y + h + 7, -rotation, x + w / 2, y + h / 2);

      // prettier-ignore
      c.text(tp.x, tp.y, 0, 0, sensorState, mxConstants.ALIGN_CENTER, mxConstants.ALIGN_MIDDLE, null, null, null, null, -rotation, null);
    }

    const drawArrowBack = (canvas: mxgraph.mxSvgCanvas2D): void => {
      // prettier-ignore
      canvas.setStrokeColor(this.style[styleHighlightColor()] ?? processVariableColor ?? this.style[mxConstants.STYLE_FILLCOLOR]);
      canvas.setStrokeWidth(6);

      canvas.begin();
      canvas.moveTo(0.15 * w, 0.5 * h);
      canvas.lineTo(0.85 * w, 0.5 * h);
      canvas.moveTo(0.78 * w, 0.5 * h);
      canvas.lineTo(0.55 * w, 0.27 * h);
      canvas.moveTo(0.78 * w, 0.5 * h);
      canvas.lineTo(0.55 * w, 0.73 * h);
      canvas.stroke();
      canvas.end();
    };
    const drawArrow = (canvas: mxgraph.mxSvgCanvas2D): void => {
      canvas.setStrokeColor(this.style[mxConstants.STYLE_STROKECOLOR]);
      canvas.setStrokeWidth(2);

      canvas.begin();
      canvas.moveTo(0.2 * w, 0.5 * h);
      canvas.lineTo(0.8 * w, 0.5 * h);
      canvas.moveTo(0.78 * w, 0.5 * h);
      canvas.lineTo(0.58 * w, 0.3 * h);
      canvas.moveTo(0.78 * w, 0.5 * h);
      canvas.lineTo(0.58 * w, 0.7 * h);
      canvas.stroke();
      canvas.end();
    };

    const drawLines = (canvas: mxgraph.mxSvgCanvas2D): void => {
      const border = h / 4;

      canvas.begin();
      canvas.moveTo(h - border, 0 + border);
      canvas.lineTo(0 + border, h - border);

      let padding = border / 2;

      canvas.moveTo(h - border - padding, 0 + border - padding);
      canvas.lineTo(0 + border - padding, h - border - padding);

      padding = padding * -1;
      canvas.moveTo(h - border - padding, 0 + border - padding);
      canvas.lineTo(0 + border - padding, h - border - padding);
      canvas.stroke();
      canvas.end();
    };

    const hw = w / 2;
    const hh = h / 2;

    // prettier-ignore
    c.setFillColor(this.style[styleHighlightColor()] ?? this.style[styleActuator()] ?? processVariableColor ?? this.style[mxConstants.STYLE_FILLCOLOR]);
    c.setStrokeAlpha(1);
    c.setFillAlpha(1);
    c.setStrokeColor(this.style[mxConstants.STYLE_STROKECOLOR]);
    c.setStrokeWidth(this.style[mxConstants.STYLE_STROKEWIDTH]);
    super.paintVertexShape(c, x, y, w, h);

    c.translate(x, y);

    drawLines(c);
    drawArrowBack(c);

    if (it(isVirtualProcessVariable(processVariable))) {
      const r = 12;

      c.setFillColor(processVariableColor);
      c.setStrokeColor(this.style[mxConstants.STYLE_STROKECOLOR]);
      c.setStrokeWidth(this.style[mxConstants.STYLE_STROKEWIDTH]);
      c.begin();
      c.ellipse(w - r + 3, h - r + 3, r, r);
      c.fillAndStroke();
      c.end();
    }
    if (it(isOfflineProcessVariable(processVariable))) {
      const r = 16;

      c.setFillColor('#FFFFFF');
      c.begin();
      c.ellipse(w / 2 - r / 2, h / 2 - r / 2, r, r);
      c.fill();
      c.end();
    }
    if (actuatorValue !== ActuatorKindStyle.None) {
      c.setStrokeColor(this.style[mxConstants.STYLE_STROKECOLOR]);
      c.setStrokeWidth(this.style[mxConstants.STYLE_STROKEWIDTH]);
      c.setFillColor(actuatorColor());

      super.paintVertexShape(c, 0, 0, w, h);

      if (actuatorValue === ActuatorKindStyle.Provisional) {
        c.setFillColor('#FFFFFF');
        c.begin();
        c.moveTo(0, w / 2);
        c.lineTo(w / 2, 0);
        c.lineTo(w, w / 2);
        c.lineTo(0, w / 2);
        c.fill();
      }

      drawLines(c);

      if (actuatorValue === ActuatorKindStyle.Manual) {
        const d = 16;

        c.setFillColor('#FFFFFF');
        c.begin();
        c.ellipse(w / 2 - d / 2, h / 2 - d / 2, d, d);
        c.fill();
        c.end();
      }
      drawArrowBack(c);
    }

    drawArrow(c);

    c.setStrokeColor(this.style[styleBorderColor()]);
    c.setStrokeWidth(this.style[styleBorderWidth()]);
    c.begin();
    c.moveTo(hw - 1.5, -1.5);
    c.lineTo(w, hh);
    c.lineTo(hw, h);
    c.lineTo(0, hh);
    c.lineTo(hw + 1.5, -1.5);
    c.stroke();
  }

  public getTextRotation(): number {
    // text label is always above model, so text rotation is prevented
    return 0;
  }
}
