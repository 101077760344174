import { mxConstants, mxEllipse, mxgraph } from '@workbench/dts/mxg';

export class SubModelPortShape extends mxEllipse {
  public readonly constraints: mxgraph.mxConnectionConstraint[] = [];

  public paintVertexShape(
    c: mxgraph.mxSvgCanvas2D,
    x: number,
    y: number,
    w: number,
    h: number,
  ): void {
    c.setFillColor(this.style[mxConstants.STYLE_FILLCOLOR]);
    c.setFillAlpha(1);
    c.setStrokeAlpha(1);
    c.setStrokeColor(this.style[mxConstants.STYLE_STROKECOLOR]);
    c.setStrokeWidth(this.style[mxConstants.STYLE_STROKEWIDTH]);

    super.paintVertexShape(c, x, y, w, h);
  }
}
