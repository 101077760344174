import { MfmModel } from '@workbench/business-logic/models/mfm.model';
import { ResourceConcept } from '@workbench/common/enums/resource-concept.enum';
import { it, or } from '@workbench/common/utils/logical-utility';
import { mxCell, mxConstants, mxGeometry, mxgraph, mxPoint } from '@workbench/dts/mxg';
import {
  isAND,
  isFunction,
  isObjective,
  isRelation,
  isSIN,
  isSOU,
  isStructure,
  isSUB,
  isTXT,
  isVOR,
} from '@workbench/multilevel-flow-modeling/core/mfm-core';
import {
  getConceptPerimeter,
  getConceptShape,
  getDefaultConceptStyles,
} from './mfm-shape/shape-registrar';
import { updateStyle } from './utils/style-util';

export class CellBuilder {
  constructor() {}

  public create(concept: ResourceConcept): mxgraph.mxCell {
    const c = (): ResourceConcept => concept;

    if (it(isTXT(c))) {
      return this.createNote(concept);
    }
    if (it(isSIN(c))) {
      return this.createSink(concept);
    }
    if (it(isSOU(c))) {
      return this.createSource(concept);
    }
    if (it(isSUB(c))) {
      return this.createSubModel(concept);
    }
    if (it(isStructure(c))) {
      return this.createStructure(concept);
    }
    if (it(isFunction(c))) {
      return this.createFunction(concept);
    }
    if (it(isObjective(c))) {
      return this.createObjective(concept);
    }
    if (it(or(isAND(c), isVOR(c)))) {
      return this.createLogicalGate(concept);
    }
    if (it(isRelation(c))) {
      return this.createRelation(concept);
    }
    throw new Error(`ArgumentOutOfBoundsException. ${concept}`);
  }

  private createSubModel(concept: ResourceConcept): mxgraph.mxCell {
    const cell: mxgraph.mxCell = new mxCell();
    const style =
      `${mxConstants.STYLE_SHAPE}=${getConceptShape(concept)};` +
      `${mxConstants.STYLE_WHITE_SPACE}=wrap;` +
      `${mxConstants.STYLE_VERTICAL_LABEL_POSITION}=top;` +
      `${mxConstants.STYLE_VERTICAL_ALIGN}=bottom;` +
      `${mxConstants.STYLE_ALIGN}=left;` +
      `${mxConstants.STYLE_RESIZABLE}=0;` +
      `${mxConstants.STYLE_WHITE_SPACE}=nowrap`;

    cell.setConnectable(false);
    cell.setGeometry(new mxGeometry(0, 0, 200, 150));
    cell.setStyle(style);
    cell.setVertex(true);
    // TODO: Remove and move MFM to separate Map<id, MfmModel>
    cell.mfm = new MfmModel();
    cell.mfm.conceptId = concept;

    return cell;
  }

  private createStructure(concept: ResourceConcept): mxgraph.mxCell {
    const cell: mxgraph.mxCell = new mxCell();
    const style =
      `${mxConstants.STYLE_SHAPE}=${getConceptShape(concept)};` +
      `${mxConstants.STYLE_WHITE_SPACE}=wrap;` +
      `${mxConstants.STYLE_VERTICAL_LABEL_POSITION}=top;` +
      `${mxConstants.STYLE_VERTICAL_ALIGN}=bottom;` +
      `${mxConstants.STYLE_ALIGN}=left;` +
      `minWidth=280;` +
      `minHeight=90;`;

    cell.setVertex(true);
    cell.setConnectable(false);
    cell.setGeometry(new mxGeometry(0, 0, 400, 240));
    cell.setStyle(style);
    // TODO: Remove and move MFM to separate Map<id, MfmModel>
    cell.mfm = new MfmModel();
    cell.mfm.conceptId = concept;

    return cell;
  }

  private createFunction(concept: ResourceConcept): mxgraph.mxCell {
    return this.createVertex(concept);
  }

  private createLogicalGate(concept: ResourceConcept): mxgraph.mxCell {
    return this.createFunction(concept);
  }

  private createObjective(concept: ResourceConcept): mxgraph.mxCell {
    return this.createFunction(concept);
  }

  private createRelation(concept: ResourceConcept): mxgraph.mxCell {
    let style =
      `${mxConstants.STYLE_ENDARROW}=mfm-${concept};` +
      `${mxConstants.STYLE_ENDSIZE}=16;` +
      `${mxConstants.STYLE_ENDFILL}=0;`;

    if (concept === ResourceConcept.Me) {
      style =
        `${mxConstants.STYLE_ENDARROW}=diamondThin;` +
        `${mxConstants.STYLE_ENDSIZE}=16;` +
        `${mxConstants.STYLE_ENDFILL}=0;`;
    }
    if (concept === ResourceConcept.Sha) {
      style =
        `${mxConstants.STYLE_STARTARROW}=circle;` +
        `${mxConstants.STYLE_ENDARROW}=circle;` +
        `${mxConstants.STYLE_STARTSIZE}=3;` +
        `${mxConstants.STYLE_ENDSIZE}=3;` +
        `${mxConstants.STYLE_STARTFILL}=1;` +
        `${mxConstants.STYLE_ENDFILL}=1;`;
    }
    const cell: mxgraph.mxCell = new mxCell('', new mxGeometry(0, 0, 32, 32), style);

    cell.geometry.setTerminalPoint(new mxPoint(0, 32), true);
    cell.geometry.setTerminalPoint(new mxPoint(32, 0), false);
    cell.geometry.relative = true;
    cell.setEdge(true);
    // TODO: Remove and move MFM to separate Map<id, MfmModel>
    cell.mfm = new MfmModel();
    cell.mfm.conceptId = concept;

    return cell;
  }

  private createSink(concept: ResourceConcept): mxgraph.mxCell {
    const cell = this.createFunction(concept);

    cell.mfm.exposed = true;

    return cell;
  }

  private createSource(concept: ResourceConcept): mxgraph.mxCell {
    const cell = this.createFunction(concept);

    cell.mfm.exposed = true;

    return cell;
  }

  private createNote(concept: ResourceConcept): mxgraph.mxCell {
    const cell = this.createFunction(concept);

    cell.setConnectable(false);
    cell.setStyle(updateStyle(cell.style, mxConstants.STYLE_ALIGN, mxConstants.ALIGN_LEFT));

    return cell;
  }

  private createVertex(concept: ResourceConcept): mxgraph.mxCell {
    const cell: mxgraph.mxCell = new mxCell();
    const style =
      `${mxConstants.STYLE_SHAPE}=${getConceptShape(concept)};` +
      `${mxConstants.STYLE_STROKEWIDTH}=2;` +
      `${mxConstants.STYLE_RESIZABLE}=0;` +
      `${mxConstants.STYLE_VERTICAL_LABEL_POSITION}=top;` +
      `${mxConstants.STYLE_VERTICAL_ALIGN}=bottom;` +
      `${mxConstants.STYLE_PERIMETER}=${getConceptPerimeter(concept)};` +
      `${getDefaultConceptStyles(concept)}`;

    cell.setVertex(true);
    cell.setConnectable(true);
    cell.setGeometry(new mxGeometry(0, 0, 32, 32));
    cell.setStyle(style);
    // TODO: Remove and move MFM to separate Map<id, MfmModel>
    cell.mfm = new MfmModel();
    cell.mfm.conceptId = concept;

    return cell;
  }
}
