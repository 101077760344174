import { arc, deg2rad, translate } from '@workbench/common/utils/geometry-util';
import { mxConnectionConstraint, mxConstants, mxgraph, mxPoint, mxShape } from '@workbench/dts/mxg';

export const constraintInlet = (): mxgraph.mxConnectionConstraint =>
  new mxConnectionConstraint(new mxPoint(0.5, 1), true, 'in');
export const constraintOutlet = (): mxgraph.mxConnectionConstraint =>
  new mxConnectionConstraint(new mxPoint(0.5, 0), true, 'out');

export class AndShape extends mxShape {
  public readonly constraints: mxgraph.mxConnectionConstraint[] = [
    constraintInlet(),
    constraintOutlet(),
  ];

  public paintVertexShape(
    c: mxgraph.mxSvgCanvas2D,
    x: number,
    y: number,
    w: number,
    h: number,
  ): void {
    const padding = 6;
    const heightPadding = 2;
    const arc0 = arc((w - padding * 2) / 2, deg2rad(0), deg2rad(-90)).map(p =>
      translate(p, x + w / 2, y + h / 2 - heightPadding),
    );
    const arc1 = arc((w - padding * 2) / 2, deg2rad(-90), deg2rad(-180)).map(p =>
      translate(p, x + w / 2, y + h / 2 - heightPadding),
    );

    c.begin();
    c.setFillColor(this.style[mxConstants.STYLE_FILLCOLOR]);
    c.setStrokeColor(this.style[mxConstants.STYLE_STROKECOLOR]);
    c.setStrokeWidth(2);
    c.setLineJoin('round');

    c.moveTo(x + padding, y + h / 2 - heightPadding);
    c.lineTo(x + padding, y + h - heightPadding);
    c.lineTo(x + w - padding, y + h - heightPadding);
    c.lineTo(x + w - padding, y + h / 2 - heightPadding);
    c.curveTo(arc0[0].x, arc0[0].y, arc0[1].x, arc0[1].y, arc0[2].x, arc0[2].y);
    c.curveTo(arc1[0].x, arc1[0].y, arc1[1].x, arc1[1].y, arc1[2].x, arc1[2].y);

    //+ sign
    const addPadding = 4;

    c.moveTo(x + w / 2 + addPadding, y + h / 2);
    c.lineTo(x + w / 2 - addPadding, y + h / 2);
    c.moveTo(x + w / 2, y + h / 2 - addPadding);
    c.lineTo(x + w / 2, y + h / 2 + addPadding);

    c.end();
    c.fillAndStroke();
    c.begin();
  }

  public getTextRotation(): number {
    // text label is always above model, so text rotation is prevented
    return 0;
  }
}
