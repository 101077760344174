import { actuatorKind, ActuatorKindStyle } from '@workbench/common/types/actuator-kind.type';
import { processVariableValue } from '@workbench/common/types/process-variable-value.type';
import { getNotEmpty } from '@workbench/common/utils/string-util';
import { mxConstants, mxEllipse, mxgraph } from '@workbench/dts/mxg';
import {
  styleActuator,
  styleGlowColor,
  styleOutlineColor,
  styleProcessVariable,
} from '../extensions/custom-style';
import { actuatorColor, processVariableStyleColor } from './colors';
import { paintShapeGlow } from './shape-glow';

export abstract class ReasoningFaultBaseShape extends mxEllipse {
  public paintVertexShape(
    c: mxgraph.mxSvgCanvas2D,
    x: number,
    y: number,
    w: number,
    h: number,
  ): void {
    // The reasoning state shapes have to be always in one and the only one orientation.
    // If the vertex has a rotation value, we rotate it in the opposite direction with the same angle

    c.rotate(-(this.style[mxConstants.STYLE_ROTATION] ?? 0), null, null, x + w / 2, y + h / 2);

    super.paintVertexShape(c, x, y, w, h);

    const glowColor = this.style[styleGlowColor()];
    const outlineColor = this.style[styleOutlineColor()];

    if (glowColor) {
      paintShapeGlow(c, x, y, w, h, glowColor);
    }

    if (outlineColor) {
      const d = 12;

      c.setFillColor(outlineColor);
      c.setFillAlpha(1);
      c.setStrokeAlpha(0);
      super.paintVertexShape(c, x - d, y - d, w + 2 * d, h + 2 * d);
    }

    c.setFillAlpha(1);
    c.setStrokeAlpha(1);
    c.setStrokeWidth(2);
    c.setStrokeColor(this.style[mxConstants.STYLE_STROKECOLOR]);
    c.setFillColor(getNotEmpty(this.getFillColor(), '#FFFFFF'));
    super.paintVertexShape(c, x, y, w, h);

    this.drawStateIcon(c, x, y, w, h);
  }

  public getTextRotation(): number {
    // text label is always above model, so text rotation is prevented
    return 0;
  }

  protected tryGetActuatorColor(): string | null {
    return actuatorKind(this.style[styleActuator()]) !== ActuatorKindStyle.None
      ? actuatorColor()
      : null;
  }

  protected tryGetProcessVariableColor(): string | null {
    return (
      processVariableStyleColor(processVariableValue(this.style[styleProcessVariable()])) ?? null
    );
  }

  protected neutralColor(): '#CECECE' {
    return '#CECECE';
  }

  protected abstract drawStateIcon(
    c: mxgraph.mxSvgCanvas2D,
    x: number,
    y: number,
    w: number,
    h: number,
  ): void;

  protected abstract getFillColor(): string;
}
