import { createAction, createActionGroup, props } from '@ngrx/store';
import { ResourceConcept } from '@workbench/common/enums/resource-concept.enum';
import { ModelFile } from '@workbench/common/models/model-file.model';
import { ModelBuilderMode } from '@workbench/model-project/model-builder-mode.enum';
import { ExtendedSyntaxIssues } from '@workbench/multilevel-flow-modeling/syntax-issues-section/extended-syntax-issue.model';
import { CausalAnalysisItemDescription } from './causal-analysis-item-description.model';
import { MfmConceptConfiguration } from './mfm-model.model';

export const modelBuilderAction = createActionGroup({
  source: 'Model Builder',
  events: {
    singleSubModelSelected: props<{ concept: MfmConceptConfiguration }>(),
  },
});

export const applyStash = createAction(
  '[Model Builder] Apply Stash',
  props<{ id: string; clear: boolean }>(),
);
export const builderEditModeDeactivated = createAction('[Model Builder] Edit mode deactivated');
export const builderLabelsModeDeactivated = createAction(
  '[Model Builder] Label Editor mode deactivated',
);
export const builderReasoningModeDeactivated = createAction(
  '[Model Builder] Reasoning mode deactivated',
);
export const changeConceptProperty = createAction(
  '[Model Builder] Change Concept Property',
  props<{ ids: string[]; changes: Partial<MfmConceptConfiguration> }>(),
);
export const checkObsoleteConceptsExist = createAction(
  '[Model Builder] Check For Obsolete Concepts In The Model',
);
export const copySelection = createAction('[Model Builder] Copy Selection');
export const exportJsonFile = createAction(
  '[Model Builder] Export JSON',
  props<{ data: unknown; suffix: string }>(),
);
export const gotoBuilderEditMode = createAction(
  '[Model Builder] Initiate switching to the Edit mode',
);
export const gotoBuilderEditModeRejected = createAction(
  '[Model Builder] Switching to the Builder Edit mode is rejected',
);
export const gotoBuilderEditModeResolved = createAction(
  '[Model Builder] Switching to the Builder Edit mode is resolved',
);
export const gotoLabelsMode = createAction('[Model Builder] Initiate switching to the Labels mode');
export const gotoLabelsModeRejected = createAction(
  '[Model Builder] Switching to the Labels mode is rejected',
);
export const gotoLabelsModeResolved = createAction(
  '[Model Builder] Switching to the Labels mode is resolved',
);
export const gotoReasoningMode = createAction(
  '[Model Builder] Initiate switching to the Reasoning mode',
);
export const gotoReasoningModeRejected = createAction(
  '[Model Builder] Switching to the Reasoning mode is rejected',
  props<{ result: ExtendedSyntaxIssues }>(),
);
export const gotoReasoningModeResolved = createAction(
  '[Model Builder] Switching to the Reasoning mode is resolved',
);
export const importModelFromFile = createAction(
  '[Model Builder] Import Model From File',
  props<{ name: string; data: unknown }>(),
);
export const pasteSelection = createAction('[Model Builder] Paste Selection');
export const patchModel = createAction(
  '[Model Builder] Patch Model',
  props<{ updates: { id: string; changes: Partial<MfmConceptConfiguration> }[] }>(),
);
export const setBuilderMode = createAction(
  '[Model Builder] Set Builder Mode',
  props<{ mode: ModelBuilderMode }>(),
);
export const setCausalAnalysisDescription = createAction(
  '[Label Editor] Set Causal Analysis Description',
  props<{ data: CausalAnalysisItemDescription[] }>(),
);
export const setImportedModel = createAction(
  '[Model Builder] Set Imported Model',
  props<{ model: unknown[] }>(),
);
export const setMfmModel = createAction(
  '[Model Builder] Set Mfm Model',
  props<{ model: MfmConceptConfiguration[] }>(),
);
export const setModelMetadata = createAction(
  '[Model Builder] Set Model Metadata',
  props<{ metadata: unknown | undefined }>(),
);
export const setInvalidSubModels = createAction(
  '[Model Builder] Set Invalid Sub-models',
  props<{ ids: string[] }>(),
);
export const setModelValidationResult = createAction(
  '[Model Builder] Set Validation Result',
  props<{ result: ExtendedSyntaxIssues }>(),
);
export const setPointedConcept = createAction(
  '[Model] Set Pointed Concept',
  props<{ id: string }>(),
);
export const setPermissions = createAction(
  '[Model Builder] Set Permissions',
  props<{ permission: { delete: boolean; modify: boolean; read: boolean; write: boolean } }>(),
);
export const setLockedState = createAction(
  '[Model Builder] Set Locked State',
  props<{ locked: boolean; lockedBy: number }>(),
);
export const setProject = createAction(
  '[Model Builder] Set Project',
  props<{
    project: Partial<ModelFile> & {
      lock?: { id: number; name: string };
    };
  }>(),
);
export const setVersionOpened = createAction(
  '[Model Builder] Is the Opened Model an Intermediate Version',
  props<{ flag: boolean }>(),
);
export const setProjectVersion = createAction(
  '[Model Builder] Set Project Version',
  props<{ version: string }>(),
);
export const setSelectedMfmModelItems = createAction(
  '[Model Builder] Set Selected Model Items',
  props<{ ids: string[] }>(),
);
export const stashConcept = createAction(
  '[Model Builder] Stash Concept',
  props<{ concept: MfmConceptConfiguration }>(),
);
export const transformConcept = createAction(
  '[Model Builder] Transform Concept',
  props<{ id: string; newType: ResourceConcept }>(),
);
export const updateCausalAnalysisDescription = createAction(
  '[Label Editor] Update Item Description',
  props<{ changes: CausalAnalysisItemDescription[] }>(),
);
export const updateConceptProperties = createAction(
  '[Model Builder] Update Concept Properties',
  props<{ ids: string[]; changes: Partial<MfmConceptConfiguration> }>(),
);
export const updateModel = createAction(
  '[Model Builder] Update Model',
  props<{ updates: MfmConceptConfiguration[] }>(),
);
