import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import {
  CausalAnalysisPropagationPath,
  CausalAnalysisPropagationPathNode,
} from '@workbench/common/models/causal-reasoning-path.model';
import { getCausalTree } from '@workbench/multilevel-flow-modeling/core/causal-analysis';
import { AppState } from '@workbench/state';

const selectFeatureState = createFeatureSelector<CausalAnalysisPropagationPath[]>('causalAnalysis');

export const selectCausalAnalysisData = createSelector(selectFeatureState, state => state);
export const selectCausalTree = (
  condition: Partial<{
    rootCause: CausalAnalysisPropagationPathNode;
    endConsequence: CausalAnalysisPropagationPathNode;
  }>,
): MemoizedSelector<AppState, CausalAnalysisPropagationPath[]> =>
  createSelector(selectCausalAnalysisData, (data: CausalAnalysisPropagationPath[]) =>
    getCausalTree(data, condition),
  );
