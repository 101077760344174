export type ActuatorKind = 'manual' | 'automatic';

export const enum ActuatorKindStyle {
  None = 0,
  Automatic = 1,
  Manual = 2,
  Provisional = 3,
}

export const actuatorKind = (value: string | number): ActuatorKindStyle => {
  // prettier-ignore
  switch ((value ?? '').toString().toLowerCase()) {
    case '1': case 'automatic': return ActuatorKindStyle.Automatic;
    case '2': case 'manual': return ActuatorKindStyle.Manual;
    case '3': case 'provisional': return ActuatorKindStyle.Provisional;
    default: return ActuatorKindStyle.None;
  }
};
