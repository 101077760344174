import { mxgraph } from '@workbench/dts/mxg';
import { ControlFunctionShape } from './control-function-shape';

export class MaintainShape extends ControlFunctionShape {
  public paintVertexShape(
    c: mxgraph.mxSvgCanvas2D,
    x: number,
    y: number,
    w: number,
    h: number,
  ): void {
    super.paintVertexShape(c, x, y, w, h);

    this.paintLabel(c, x, y, w, h, 'm');
  }
}
