"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * Virtual any type for the property decorator.
 */
var Any = /** @class */function () {
  function Any() {}
  return Any;
}();
exports.Any = Any;
