import { mxgraph, mxShape } from '@workbench/dts/mxg';

export class ControlFlowStructureShape extends mxShape {
  public paintVertexShape(
    c: mxgraph.mxSvgCanvas2D,
    x: number,
    y: number,
    w: number,
    h: number,
  ): void {
    c.begin();
    c.setFillColor('#FFEAA3');
    c.setStrokeColor('none');
    c.setFillAlpha(0.4);
    c.setStrokeWidth(3);
    c.roundrect(x, y, w, h, 25, 25);
    c.fillAndStroke();
    c.end();
  }
}
