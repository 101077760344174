import { createAction, props } from '@ngrx/store';
import { ReasoningFaultKind } from '@workbench/business-logic/enums/reasoning-fault-kind.enum';
import {
  CausalAnalysisPropagationPath,
  CausalAnalysisPropagationPathNode,
} from '@workbench/common/models/causal-reasoning-path.model';

export const buildModelForCausalAnalysis = createAction(
  '[Graph] Build MFM Model For Causal Analysis',
);
export const clearHighlights = createAction('[Graph] Clear Highlights');
export const deleteSelectedCells = createAction(
  '[Graph] Delete Selected Cells',
  props<{ cascade: boolean }>(),
);
export const exportToFile = createAction('[Graph] Export Model to File');
export const exportToFileExtendedModel = createAction('[Graph] Export Extended Model to File');
export const exportToPortal = createAction('[Graph] Export to Portal');
export const focusGraphCell = createAction('[Graph] Focus Graph Cell', props<{ id: string }>());
export const graphCellPointed = createAction('[Graph] Graph Cell Pointed', props<{ id: string }>());
export const graphChanged = createAction('[Graph] Changed');
export const graphInitialized = createAction('[Graph] Initialized');
export const graphZoomActual = createAction('[Graph] Zoom Actual');
export const graphZoomAll = createAction('[Graph] Zoom All');
export const graphZoomIn = createAction('[Graph] Zoom In');
export const graphZoomOut = createAction('[Graph] Zoom Out');
export const graphZoomSystem = createAction('[Graph] Zoom System');
export const highlightEndConsequencesTree = createAction(
  '[Graph] Highlight Consequences Tree',
  props<{ endConsequence: CausalAnalysisPropagationPathNode }>(),
);
export const highlightRootCausesTree = createAction(
  '[Graph] Highlight Root Causes Tree',
  props<{ rootCause: CausalAnalysisPropagationPathNode }>(),
);
export const highlightCausalPaths = createAction(
  '[Graph] Highlight Causal Paths',
  props<{
    paths: CausalAnalysisPropagationPath[];
    include?: Partial<{
      fault: { functionId: string; fault: ReasoningFaultKind };
      causes: CausalAnalysisPropagationPathNode[];
      consequences: CausalAnalysisPropagationPathNode[];
      inspectionPoints: true;
      preventiveActions: true;
      displayState: true;
    }>;
  }>(),
);
export const runReasoning = createAction('[Graph] Run Reasoning');
export const setVisibleMfmEntities = createAction(
  '[Graph] Set Visible MFM Entities',
  props<{ ids: string[] }>(),
);
export const toggleGridDisplay = createAction(
  '[Graph] Toggle Grid Display',
  props<{ visible: boolean }>(),
);
export const updateGraphCellStyles = createAction('[Graph] Update Graph Cell Styles');
export const validateModel = createAction('[Graph] Validate Model');
export const validateModelBeforeReasoning = createAction('[Graph] Validate Model Before Reasoning');
