import { mxConstants, mxgraph, mxHexagon } from '@workbench/dts/mxg';
import { styleGlowColor, styleHighlightColor } from '../extensions/custom-style';
import { paintShapeGlow } from './shape-glow';

export class BalanceShape extends mxHexagon {
  public readonly constraints: mxgraph.mxConnectionConstraint[] = [];

  public paintVertexShape(
    c: mxgraph.mxSvgCanvas2D,
    x: number,
    y: number,
    w: number,
    h: number,
  ): void {
    const glowColor = this.style[styleGlowColor()];

    if (glowColor) {
      paintShapeGlow(c, x, y, w, h, glowColor);
    }

    const padding = 2;

    // prettier-ignore
    c.setFillColor(this.style[styleHighlightColor()] ?? this.style[mxConstants.STYLE_FILLCOLOR]);
    c.setStrokeColor(this.style[mxConstants.STYLE_STROKECOLOR]);
    c.setStrokeWidth(this.style[mxConstants.STYLE_STROKEWIDTH]);

    super.paintVertexShape(c, x, y + padding / 2, w, h - padding);

    c.begin();
    c.moveTo(0, 0.5 * h);
    c.lineTo(0.775 * w, 1);
    c.moveTo(0.1 * w, 0.72 * h);
    c.lineTo(0.9 * w, 0.22 * h);
    c.moveTo(0.25 * w, 0.9 * h);
    c.lineTo(w, 0.45 * h);
    c.stroke();
    c.end();
  }
}
