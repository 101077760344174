import { mxgraph, mxShape } from '@workbench/dts/mxg';

export class SubModelShape extends mxShape {
  public readonly constraints: mxgraph.mxConnectionConstraint[] = [];

  public paintVertexShape(
    c: mxgraph.mxSvgCanvas2D,
    x: number,
    y: number,
    w: number,
    h: number,
  ): void {
    c.begin();
    c.setFillColor('#EFEFEF');
    c.setStrokeColor('#999999');
    c.setFillAlpha(1);
    c.setStrokeWidth(3);
    c.roundrect(x, y, w, h, 25, 25);
    c.fillAndStroke();
    c.end();
  }
}
