import { arc, deg2rad, translate } from '@workbench/common/utils/geometry-util';
import { exist, it } from '@workbench/common/utils/logical-utility';
import { mxConnectionConstraint, mxConstants, mxgraph, mxPoint, mxShape } from '@workbench/dts/mxg';
import { styleLogicalGateValue } from '../extensions/custom-style';

export const constraintInlet = (): mxgraph.mxConnectionConstraint =>
  new mxConnectionConstraint(new mxPoint(0.5, 1), true, 'in');
export const constraintOutlet = (): mxgraph.mxConnectionConstraint =>
  new mxConnectionConstraint(new mxPoint(0.5, 0), true, 'out');

export class VotingOrShape extends mxShape {
  public readonly constraints: mxgraph.mxConnectionConstraint[] = [
    constraintInlet(),
    constraintOutlet(),
  ];

  public paintVertexShape(
    c: mxgraph.mxSvgCanvas2D,
    x: number,
    y: number,
    w: number,
    h: number,
  ): void {
    const logicGates = this.style[styleLogicalGateValue()] ?? 'N';
    const textHeight = 12;

    const arc0 = arc(20, deg2rad(180), deg2rad(240)).map(p => translate(p, x + w - 6, y + h - 12));
    const arc1 = arc(20, deg2rad(-60), deg2rad(0)).map(p => translate(p, x + 6, y + h - 12));
    const arc2 = arc(20, deg2rad(-60), deg2rad(-120)).map(p => translate(p, x + w / 2, y + h + 15));

    c.begin();
    c.setFillColor('#FFFFFF');
    c.setStrokeColor('#000000');
    c.setStrokeWidth(2);
    c.setLineJoin('round');

    c.moveTo(arc0[0].x, arc0[0].y);
    c.curveTo(arc0[0].x, arc0[0].y, arc0[1].x, arc0[1].y, arc0[2].x, arc0[2].y);

    c.lineTo(arc1[0].x, arc1[0].y);
    c.curveTo(arc1[0].x, arc1[0].y, arc1[1].x, arc1[1].y, arc1[2].x, arc1[2].y);

    c.lineTo(arc2[0].x, arc2[0].y);
    c.curveTo(arc2[0].x, arc2[0].y, arc2[1].x, arc2[1].y, arc2[2].x, arc2[2].y);

    c.lineTo(arc0[0].x, arc0[0].y);

    c.end();
    c.fillAndStroke();
    c.begin();

    if (it(exist(() => logicGates))) {
      c.text(
        x + w / 2,
        y + h / 2 - textHeight / 2,
        w,
        h,
        logicGates.toString(),
        mxConstants.ALIGN_CENTER,
        mxConstants.ALIGN_CENTER,
        null,
        null,
        null,
        null,
        null,
        null,
      );
    }
  }

  public getTextRotation(): number {
    // text label is always above model, so text rotation is prevented
    return 0;
  }
}
