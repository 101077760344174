import { createActionGroup, props } from '@ngrx/store';
import { LibraryComponentDetailedModel } from '@workbench/common/models/library-component.model';
import { LibraryTagging } from '@workbench/common/models/library-tagging.model';
import { ModelTreeResponse } from '@workbench/common/models/model-tree-response.model';

export const modelApiActions = createActionGroup({
  source: 'Model/API',
  events: {
    fetchGenericTags: props<{ guid: string; version: string }>(),
    fetchGenericTagsFault: props<{ message: string }>(),
    fetchGenericTagsSucceeded: props<{ guid: string; version: string; data: LibraryTagging }>(),
    fetchLibraryModelDetails:
      props<{ conceptId: string; libraryModelId: string; version: string; tag: string }>(),
    fetchLibraryModelDetailsFault: props<{ message: string; conceptId: string }>(),
    fetchLibraryModelDetailsSucceeded: props<{
      conceptId: string;
      latest: boolean;
      subModel: LibraryComponentDetailedModel;
      tag: string;
    }>(),
    resolveModelAndExport: props<{ model: unknown }>(),
    resolveModelAndExportSucceeded: props<{ model: unknown }>(),
    resolveModelAndRunReasoning: props<{ model: unknown }>(),
    resolveModelAndValidate: props<{ model: unknown }>(),
    resolveModelAndValidateBeforeReasoning: props<{ model: unknown }>(),
    resolveModelFault: props<{ message: string }>(),
    resolveModelTree: props<{ model: unknown }>(),
    resolveModelTreeFault: props<{ message: string }>(),
    resolveModelTreeSucceeded: props<{ response: ModelTreeResponse }>(),
  },
});
