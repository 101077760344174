import { ResourceConcept } from '@workbench/common/enums/resource-concept.enum';
import { exist, it } from '@workbench/common/utils/logical-utility';
import { mxConstants, mxEllipse, mxgraph } from '@workbench/dts/mxg';
import {
  styleGlowColor,
  styleHazardLevel,
  styleHighlightColor,
  styleObjectiveCategory,
  styleOutlineColor,
} from '../extensions/custom-style';
import { paintShapeGlow } from './shape-glow';
import { getDefaultConceptStyles } from './shape-registrar';

export class HazardShape extends mxEllipse {
  public readonly constraints: mxgraph.mxConnectionConstraint[] = [];

  public paintVertexShape(
    c: mxgraph.mxSvgCanvas2D,
    x: number,
    y: number,
    w: number,
    h: number,
  ): void {
    const glowColor = this.style[styleGlowColor()];
    const hazardLevel = this.style[styleHazardLevel()] ?? 0;
    const objectiveCategory = this.style[styleObjectiveCategory()];
    const outlineColor = this.style[styleOutlineColor()];

    const d = 16;
    const dx = w / 2 - d / 2;
    const dy = h / 2 - d / 2;
    const textHeight = 12;

    if (glowColor) {
      paintShapeGlow(c, x, y, w, h, glowColor);
    }

    if (outlineColor) {
      c.setFillColor(outlineColor);
      c.setFillAlpha(1);
      c.setStrokeAlpha(0);
      super.paintVertexShape(c, x, y, w, h);
    }

    if (it(exist(() => objectiveCategory))) {
      // prettier-ignore
      c.text(x, y + h / 2 - textHeight / 2, w, h, getLabel(objectiveCategory), mxConstants.ALIGN_CENTER, mxConstants.ALIGN_CENTER, null, null, null, null, null, null);
    }
    c.setFillAlpha(1);
    c.setStrokeAlpha(1);
    c.setStrokeColor(this.style[mxConstants.STYLE_STROKECOLOR]);
    c.setStrokeWidth(this.style[mxConstants.STYLE_STROKEWIDTH]);

    // Top half or the circle
    if (hazardLevel === 0) {
      c.setFillColor(
        this.style[styleHighlightColor()] ??
          getDefaultConceptStyles(ResourceConcept.Haz, mxConstants.STYLE_FILLCOLOR),
      );
    }
    if (hazardLevel === 1) {
      c.setFillColor('#FFFFFF');
    }
    if (hazardLevel === 2) {
      c.setFillColor(
        this.style[styleHighlightColor()] ??
          getDefaultConceptStyles(ResourceConcept.Haz, mxConstants.STYLE_FILLCOLOR),
      );
    }
    c.begin();
    c.moveTo(x + dx, y + h / 2);
    c.curveTo(x + dx, y + h / 2, x + dx, y + dy, x + w / 2, y + dy);
    c.curveTo(x + w / 2, y + dy, x - dx + w, y + dy, x - dx + w, y + h / 2);
    c.end();
    c.fill();

    // Bottom half or the circle
    if (hazardLevel === 0) {
      c.setFillColor('#FFFFFF');
    }
    if (hazardLevel === 1) {
      c.setFillColor(
        this.style[styleHighlightColor()] ??
          getDefaultConceptStyles(ResourceConcept.Haz, mxConstants.STYLE_FILLCOLOR),
      );
    }
    if (hazardLevel === 2) {
      c.setFillColor(
        this.style[styleHighlightColor()] ??
          getDefaultConceptStyles(ResourceConcept.Haz, mxConstants.STYLE_FILLCOLOR),
      );
    }
    c.begin();
    c.moveTo(x + dx, y + h / 2);
    c.curveTo(x + dx, y + h / 2, x + dx, y - dy + h, x + w / 2, y - dy + h);
    c.curveTo(x + w / 2, y - dy + h, x - dx + w, y - dy + h, x - dx + w, y + h / 2);
    c.fill();
    c.end();

    c.begin();
    c.setFillAlpha(0);
    c.ellipse(x + dx, y + dy, w - 2 * dx, h - 2 * dy);
    c.stroke();
    c.end();
  }
}

const getLabel = (category: number): string => {
  // prettier-ignore
  switch (category) {
    case 0: return 'S';
    case 1: return 'P';
    case 2: return 'E';
    default: return '';
  }
};
