import { MfmModel } from '@workbench/business-logic/models/mfm.model';
import { mxCell, mxConstants, mxGeometry, mxgraph, mxPoint } from '@workbench/dts/mxg';
import { styleSubModelPort } from '@workbench/mx-graph/extensions/custom-style';
import { MfmConceptConfiguration } from '@workbench/state/model-builder/mfm-model.model';
import { ResourceConcept } from '../enums/resource-concept.enum';
import { toMap } from './array-util';
import { exist, it, not } from './logical-utility';

/**
 * Redraw a mxCell that is a Sub Model based on the linked Library Model
 * that is placed in `mfm` property of the mxCell entity.
 *
 * @param graph a reference to an mxGraph instance
 * @param subModel a reference to mxCell that represent the Sub Model
 */
export function updateSubModel(
  graph: mxgraph.mxGraph,
  subModel: mxgraph.mxCell,
  terminals: Partial<{
    previous: MfmConceptConfiguration['subModelTerminals'];
    next: MfmConceptConfiguration['subModelTerminals'];
  }>,
): boolean {
  subModel.geometry.width = Math.max(
    200,
    Math.max(terminals.next?.inputs.length ?? 0, terminals.next?.outputs.length ?? 0) * 16 * 2,
  );
  subModel.geometry.height = Math.max(
    150,
    Math.max(terminals.next?.sinks.length ?? 0, terminals.next?.sources.length ?? 0) * 16 * 2,
  );

  return updateTerminals(graph, subModel, terminals);
}

function updateTerminals(
  graph: mxgraph.mxGraph,
  subModel: mxgraph.mxCell,
  terminals: Partial<{
    previous: MfmConceptConfiguration['subModelTerminals'];
    next: MfmConceptConfiguration['subModelTerminals'];
  }>,
): boolean {
  const pInputs = toMap(terminals.previous?.inputs ?? [], 'id');
  const nInputs = toMap(terminals.next?.inputs ?? [], 'id');
  const pOutputs = toMap(terminals.previous?.outputs ?? [], 'id');
  const nOutputs = toMap(terminals.next?.outputs ?? [], 'id');
  const pSinks = toMap(terminals.previous?.sinks ?? [], 'id');
  const nSinks = toMap(terminals.next?.sinks ?? [], 'id');
  const pSources = toMap(terminals.previous?.sources ?? [], 'id');
  const nSources = toMap(terminals.next?.sources ?? [], 'id');

  const previous = new Set([
    ...pInputs.keys(),
    ...pOutputs.keys(),
    ...pSinks.keys(),
    ...pSources.keys(),
  ]);
  const next = new Set([
    ...nInputs.keys(),
    ...nOutputs.keys(),
    ...nSinks.keys(),
    ...nSources.keys(),
  ]);
  const all = new Set([...previous, ...next]);

  // We go through all the terminals (previous and next) one by one.
  return Array.from(all.values()).reduce((changed, id) => {
    const terminalId = `${subModel.id}.${id}`;

    // If the terminal has been removed,
    // we remove it and detach all the edges
    if (previous.has(id) === true && next.has(id) === false) {
      const previousTerminal = graph.model.getCell(terminalId);

      // Detach all edges from the terminal
      flipConnections(graph, previousTerminal);
      // Remove the terminal
      graph.removeCells([previousTerminal]);

      return true;
    }

    // If the terminal has been added or updated.
    const previousPort = pInputs.get(id) ?? pOutputs.get(id) ?? pSinks.get(id) ?? pSources.get(id);
    const isNew = previous.has(id) === false && containsCell(graph, terminalId) === false;

    // If the terminal is an input, and it hasn't been added yet.
    if (nInputs.has(id) === true) {
      const total = terminals.next.inputs.length;
      const position = terminals.next.inputs.indexOf(nInputs.get(id));
      const nInput = nInputs.get(id);

      // Create the terminal if it is new (not in the graph yet)
      if (isNew) {
        const terminal = createTerminal(subModel.id, nInput, 'inputs', total, position);

        graph.addCell(terminal, subModel);

        return true;
      }

      updateTerminal(graph, subModel.id, previousPort, nInput, 'inputs', total, position);

      return true;
    }
    // If the terminal is an output
    if (nOutputs.has(id)) {
      const total = terminals.next.outputs.length;
      const position = terminals.next.outputs.indexOf(nOutputs.get(id));
      const nOutput = nOutputs.get(id);

      // Create the terminal if it is new (not in the graph yet)
      if (isNew) {
        const terminal = createTerminal(subModel.id, nOutput, 'outputs', total, position);

        graph.addCell(terminal, subModel);

        return true;
      }

      updateTerminal(graph, subModel.id, previousPort, nOutput, 'outputs', total, position);

      return true;
    }
    // If the terminal is a sink
    if (nSinks.has(id)) {
      const total = terminals.next.sinks.length;
      const position = terminals.next.sinks.indexOf(nSinks.get(id));
      const nSink = nSinks.get(id);

      // Create the terminal if it is new (not in the graph yet)
      if (isNew) {
        const terminal = createTerminal(subModel.id, nSink, 'sinks', total, position);

        graph.addCell(terminal, subModel);

        return true;
      }
      // prettier-ignore
      updateTerminal(graph, subModel.id, previousPort, nSink, 'sinks', total, position);

      return true;
    }
    // If the terminal is a source
    if (nSources.has(id)) {
      const total = terminals.next.sources.length;
      const position = terminals.next.sources.indexOf(nSources.get(id));
      const nSource = nSources.get(id);

      // Create the terminal if it is new (not in the graph yet)
      if (isNew) {
        const terminal = createTerminal(subModel.id, nSource, 'sources', total, position);

        graph.addCell(terminal, subModel);

        return true;
      }
      // prettier-ignore
      updateTerminal(graph, subModel.id, previousPort, nSource, 'sources', total, position);

      return true;
    }

    return changed;
  }, false);
}

function updateTerminal<T extends { id: string; concept: ResourceConcept; badge: string[] }>(
  graph: mxgraph.mxGraph,
  subModelId: string,
  prev: T,
  next: T,
  type: 'inputs' | 'outputs' | 'sinks' | 'sources',
  count: number,
  index: number,
): void {
  // There is a possibility that the terminal is already in the graph,
  // but it is not in the Store yet, so there is no previous value
  // with badge and concept information.
  // prettier-ignore
  const { badge: previousBadgeValue = [], concept: previousConceptValue = '' } = prev ?? { badge: [], concept: '' };
  const { badge, concept, id } = next;
  const terminal = graph.model.getCell(`${subModelId}.${id}`);

  terminal.geometry = getSubModelPortGeometry(type, count, index);
  // If the badge value was changed, we update the label
  if (previousBadgeValue.join('\t') !== badge.join('\t')) {
    terminal.mfm.label = badge.join('\t');
    terminal.setValue(terminal.mfm.label);
  }
  // If the concept type has changed
  if (previousConceptValue !== concept) {
    terminal.mfm.conceptId = concept;
  }
}

function createTerminal<T extends { id: string; concept: ResourceConcept; badge: string[] }>(
  subModelId: string,
  t: T,
  type: 'inputs' | 'outputs' | 'sinks' | 'sources',
  total: number,
  position: number,
): mxgraph.mxCell {
  const geometry = getSubModelPortGeometry(type, total, position);
  const terminal = createSubModelPortCell(`${subModelId}.${t.id}`, t.concept, t.badge, geometry);

  return terminal;
}

function getSubModelPortGeometry(
  type: 'inputs' | 'outputs' | 'sinks' | 'sources',
  count: number,
  index: number,
): mxgraph.mxGeometry {
  const d = 1 / (count + 1);
  const portSize = 16;

  switch (type) {
    case 'inputs': {
      const geometry: mxgraph.mxGeometry = new mxGeometry(d * (index + 1), 1, portSize, portSize);

      geometry.offset = new mxPoint(-portSize / 2, -portSize);
      geometry.relative = true;

      return geometry;
    }
    case 'outputs': {
      const geometry: mxgraph.mxGeometry = new mxGeometry(d * (index + 1), 0, portSize, portSize);

      geometry.offset = new mxPoint(-portSize / 2, 0);
      geometry.relative = true;

      return geometry;
    }
    case 'sinks': {
      const geometry: mxgraph.mxGeometry = new mxGeometry(1, d * (index + 1), portSize, portSize);

      geometry.offset = new mxPoint(-portSize, -portSize / 2);
      geometry.relative = true;

      return geometry;
    }
    case 'sources': {
      const geometry: mxgraph.mxGeometry = new mxGeometry(0, d * (index + 1), portSize, portSize);

      geometry.offset = new mxPoint(0, -portSize / 2);
      geometry.relative = true;

      return geometry;
    }
    default: {
      return new mxGeometry();
    }
  }
}

export function addLabelToEdge(
  edge: mxgraph.mxCell,
  graph: mxgraph.mxGraph,
  text: string,
  isSource: boolean,
): void {
  const label = createLabelCell(text, isSource);

  removeLabel(edge, graph);

  graph.addCell(label, edge);
  graph.autoSizeCell(label);
}

export function removeLabel(cell: mxgraph.mxCell, graph: mxgraph.mxGraph): void {
  if (cell.getChildCount() > 0) {
    const labels = cell.children.filter(child => it(not(exist(() => child.mfm))));

    graph.removeCells(labels);
  }
}

export function getLabel(cell: mxgraph.mxCell): string {
  return cell.mfm.label ?? false ? `${cell.mfm.label} (${cell.mfm.conceptId})` : cell.mfm.conceptId;
}

export function getConcept(cell: mxgraph.mxCell) {
  return (): ResourceConcept => cell?.mfm?.conceptId ?? ResourceConcept.Undef;
}

function createLabelCell(text: string, isSource: boolean): mxgraph.mxCell {
  const label: mxgraph.mxCell = new mxCell();

  label.setValue(text);
  label.style =
    'text=1;html=1;resizable=0;points=[];movable=0;selectable=0;align=center;verticalAlign=middle;labelBackgroundColor=#ffffff;';
  label.geometry = getLabelGeometry(isSource);
  label.vertex = true;
  label.connectable = false;
  label.geometry.relative = true;

  return label;
}

function createSubModelPortCell(
  id: string,
  conceptId: ResourceConcept,
  badge: string[],
  geometry: mxgraph.mxGeometry,
): mxgraph.mxCell {
  const cell: mxgraph.mxCell = new mxCell();
  const style =
    `${mxConstants.STYLE_FILLCOLOR}=#FFFFFF;` +
    `${mxConstants.STYLE_STROKECOLOR}=#999999;` +
    `${mxConstants.STYLE_MOVABLE}=0;` +
    `${mxConstants.STYLE_RESIZABLE}=0;` +
    `${mxConstants.STYLE_SHAPE}=sub-model-port;` +
    `${mxConstants.STYLE_PERIMETER}=${mxConstants.PERIMETER_ELLIPSE};` +
    `${mxConstants.STYLE_NOLABEL}=1;` +
    `${styleSubModelPort()}=1;` +
    `clickable=0;` +
    `selectable=0;`;

  cell.setStyle(style);
  cell.setVertex(true);
  cell.setConnectable(true);

  cell.setId(id);
  cell.setGeometry(geometry);
  cell.mfm = new MfmModel();
  cell.mfm.conceptId = conceptId;
  cell.mfm.id = cell.id;
  cell.mfm.label = badge.join('\t');
  cell.setValue(cell.mfm.label);

  return cell;
}

// Detach all edges from the vertices,
// and attach them to the new vertex if defined.
function flipConnections(
  graph: mxgraph.mxGraph,
  from: mxgraph.mxCell,
  to: mxgraph.mxCell = null,
): void {
  graph.getModel().beginUpdate();
  try {
    const edges = graph.getEdges(from);

    edges.forEach(edge => {
      graph.getModel().setTerminal(edge, to, from === edge.source);
    });
  } finally {
    graph.getModel().endUpdate();
  }
}

function getLabelGeometry(isSource: boolean): mxgraph.mxGeometry {
  if (isSource) {
    return new mxGeometry(-0.5, 0, 0, 0);
  } else {
    return new mxGeometry(0.5, 0, 0, 0);
  }
}

function containsCell(graph: mxgraph.mxGraph, id: string): boolean {
  return Object.keys(graph.model.cells).includes(id);
}
