import { Observable } from 'rxjs';

export { jsonTryParse };

function jsonTryParse<T = unknown>(source: string): Observable<T> {
  return new Observable<T>(observer => {
    try {
      const value = JSON.parse(source);

      observer.next(value);
    } catch (error) {
      observer.error(error);
    } finally {
      observer.complete();
    }

    return { unsubscribe: (): void => {} };
  });
}
