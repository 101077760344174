import {
  isOfflineProcessVariable,
  isVirtualProcessVariable,
  processVariableValue,
} from '@workbench/common/types/process-variable-value.type';
import { it } from '@workbench/common/utils/logical-utility';
import { mxConstants, mxEllipse, mxgraph, mxUtils } from '@workbench/dts/mxg';
import {
  styleBorderColor,
  styleBorderWidth,
  styleExposed,
  styleGlowColor,
  styleHighlightColor,
  styleOutlineColor,
  styleProcessVariable,
} from '../extensions/custom-style';
import { processVariableStyleColor } from './colors';
import { paintShapeGlow } from './shape-glow';

export class SinkShape extends mxEllipse {
  public readonly constraints: mxgraph.mxConnectionConstraint[] = [];

  public paintVertexShape(
    c: mxgraph.mxSvgCanvas2D,
    x: number,
    y: number,
    w: number,
    h: number,
  ): void {
    const exposed = this.style[styleExposed()] ?? 0;
    const glowColor = this.style[styleGlowColor()];
    const outlineColor = this.style[styleOutlineColor()];
    const processVariable = processVariableValue(this.style[styleProcessVariable()]);
    const processVariableColor = processVariableStyleColor(processVariable);

    if (glowColor) {
      paintShapeGlow(c, x, y, w, h, glowColor);
    }

    if (outlineColor) {
      c.setStrokeAlpha(1);
      c.setStrokeColor(outlineColor);
      c.setStrokeWidth(16);
      c.setFillAlpha(1);
      c.setFillColor(outlineColor);

      if (exposed === 0) {
        if (outlineColor) {
          super.paintVertexShape(c, x, y, w, h);
        }
      }
      if (exposed === 1) {
        c.begin();
        c.moveTo(x + w / 2, y);
        c.curveTo(x + w / 2, y, x, y, x, y + h / 2);
        c.curveTo(x, y + h / 2, x, y + h, x + w / 2, y + h);
        c.close(x + w / 2, y, null, null, null, null);
        c.fillAndStroke();
      }
    }

    c.setFillAlpha(1);
    // prettier-ignore
    c.setFillColor(this.style[styleHighlightColor()] ?? processVariableColor ?? this.style[mxConstants.STYLE_FILLCOLOR]);
    c.setStrokeAlpha(1);
    c.setStrokeColor(this.style[styleBorderColor()] ?? this.style[mxConstants.STYLE_STROKECOLOR]);
    c.setStrokeWidth(this.style[styleBorderWidth()] ?? this.style[mxConstants.STYLE_STROKEWIDTH]);

    if (exposed === 1) {
      c.begin();
      c.moveTo(x + w / 2, y);
      c.curveTo(x + w / 2, y, x, y, x, y + h / 2);
      c.curveTo(x, y + h / 2, x, y + h, x + w / 2, y + h);
      c.close(x + w / 2, y, null, null, null, null);
      c.fill();

      if (it(isVirtualProcessVariable(processVariable))) {
        const d = 12;

        c.setFillColor(processVariableColor);
        c.begin();
        c.ellipse(x + w / 2 + 2, y + h - d / 2, d, d);
        c.fillAndStroke();
      }
      if (it(isOfflineProcessVariable(processVariable))) {
        const r = 20;

        c.setFillColor('#FFFFFF');
        c.begin();
        c.moveTo(x + w / 2, y + h / 2 - r / 2);
        c.curveTo(
          x + w / 2,
          y + h / 2 - r / 2,
          x + w / 2 - r / 2,
          y + h / 2 - r / 2,
          x + w / 2 - r / 2,
          y + h / 2,
        );
        c.curveTo(
          x + w / 2 - r / 2,
          y + h / 2,
          x + w / 2 - r / 2,
          y + h / 2 + r / 2,
          x + w / 2,
          y + h / 2 + r / 2,
        );
        c.close(x + w / 2, y + h / 2 - r / 2, null, null, null, null);
        c.fill();
      }

      // draw inner lines
      const r = 16;
      const angle1 = mxUtils.toRadians(225);
      const angle2 = mxUtils.toRadians(135);

      c.setStrokeColor(this.style[mxConstants.STYLE_STROKECOLOR]);
      c.setStrokeWidth(this.style[mxConstants.STYLE_STROKEWIDTH]);
      c.begin();
      c.moveTo(x + w / 2, y + h / 2);
      c.lineTo(x + w / 2 + r * Math.cos(angle1), y + h / 2 + r * Math.sin(angle1));
      c.moveTo(x + w / 2, y + h / 2);
      c.lineTo(x + w / 2 + r * Math.cos(angle2), y + h / 2 + r * Math.sin(angle2));
      c.stroke();

      // A border around the shape (half of a circle)
      c.begin();
      c.moveTo(x + w / 2, y);
      c.curveTo(x + w / 2, y, x, y, x, y + h / 2);
      c.curveTo(x, y + h / 2, x, y + h, x + w / 2, y + h);
      c.close(x + w / 2, y, null, null, null, null);
      c.stroke();
    }
    if (exposed === 0) {
      super.paintVertexShape(c, x, y, w, h);

      if (it(isVirtualProcessVariable(processVariable))) {
        const r = 12;

        c.setFillColor(processVariableColor);
        c.ellipse(x + w - r / 2, y + h - r / 2, r, r);
        c.fillAndStroke();
      }
      if (it(isOfflineProcessVariable(processVariable))) {
        const r = 20;

        c.setFillColor('#FFFFFF');
        c.ellipse(x + w / 2 - r / 2, y + h / 2 - r / 2, r, r);
        c.fill();
      }

      // inner lines always have default color and line-width
      // so we set their color after all other elements were painted
      c.begin();
      c.setStrokeColor(this.style[mxConstants.STYLE_STROKECOLOR]);
      c.setStrokeWidth(this.style[mxConstants.STYLE_STROKEWIDTH]);
      c.moveTo(x, y + h / 2);
      c.lineTo(x + w, y + h / 2);
      c.moveTo(x + w / 2, y);
      c.lineTo(x + w / 2, y + h);
      c.rotate(45, 0, 0, x + w / 2, y + h / 2);
      c.end();
      c.stroke();
    }
    c.fillAndStroke();
  }

  public getTextRotation(): number {
    // text label is always above model, so text rotation is prevented
    return 0;
  }
}
