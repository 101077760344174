import { getNotEmpty } from '@workbench/common/utils/string-util';
import { mxgraph } from '@workbench/dts/mxg';
import { styleHighlightColor } from '../extensions/custom-style';
import { ReasoningFaultBaseShape } from './reasoning-fault-base-shape';

export class ReasoningFaultKindNormalShape extends ReasoningFaultBaseShape {
  protected drawStateIcon(
    c: mxgraph.mxSvgCanvas2D,
    x: number,
    y: number,
    w: number,
    h: number,
  ): void {
    c.setStrokeWidth(3);

    c.translate(x, y);

    c.begin();
    c.moveTo(0.27 * w, 0.5 * h);
    c.lineTo(0.36 * w, 0.5 * h);
    c.moveTo(0.455 * w, 0.5 * h);
    c.lineTo(0.545 * w, 0.5 * h);
    c.moveTo(0.64 * w, 0.5 * h);
    c.lineTo(0.73 * w, 0.5 * h);
    c.stroke();
  }

  protected getFillColor(): string {
    return getNotEmpty(
      this.style[styleHighlightColor()],
      this.tryGetActuatorColor(),
      this.tryGetProcessVariableColor(),
      this.neutralColor(),
    );
  }
}
