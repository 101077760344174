import { getNotEmpty } from '@workbench/common/utils/string-util';
import { mxgraph } from '@workbench/dts/mxg';
import { styleHighlightColor } from '../extensions/custom-style';
import { ReasoningFaultBaseShape } from './reasoning-fault-base-shape';

export class ReasoningFaultKindControlLowShape extends ReasoningFaultBaseShape {
  protected drawStateIcon(
    c: mxgraph.mxSvgCanvas2D,
    x: number,
    y: number,
    w: number,
    h: number,
  ): void {
    c.setStrokeWidth(2);
    c.setFillAlpha(0);

    c.translate(x, y);

    c.begin();

    const s = Math.min(w, h);
    const ls = s * 0.25;
    const r = (s * 0.6) / 2;
    const cx = w / 2;
    const cy = h / 2;

    c.moveTo(cx - r, cy);
    c.curveTo(cx - r, cy, cx - r, cy - r, cx, cy - r);
    c.curveTo(cx, cy - r, cx + r, cy - r, cx + r, cy);
    c.close(cx - r, cy, null, null, null, null);
    c.moveTo(cx, cy);
    c.lineTo(cx, cy + s / 5);
    c.moveTo(cx, cy + s / 5);
    c.lineTo(cx - ls, cy + s / 5 - 3);
    c.moveTo(cx, cy + s / 5);
    c.lineTo(cx - ls, cy + s / 5 + 3);
    c.moveTo(cx, cy + s / 5);
    c.lineTo(cx + ls, cy + s / 5 - 3);
    c.moveTo(cx, cy + s / 5);
    c.lineTo(cx + ls, cy + s / 5 + 3);

    c.fillAndStroke();
  }

  protected getFillColor(): string {
    return getNotEmpty(
      this.style[styleHighlightColor()],
      this.tryGetActuatorColor(),
      this.tryGetProcessVariableColor(),
    );
  }
}
