import { getNotEmpty } from '@workbench/common/utils/string-util';
import { mxgraph } from '@workbench/dts/mxg';
import { styleHighlightColor } from '../extensions/custom-style';
import { ReasoningFaultBaseShape } from './reasoning-fault-base-shape';

export class ReasoningFaultKindFalseShape extends ReasoningFaultBaseShape {
  protected drawStateIcon(
    c: mxgraph.mxSvgCanvas2D,
    x: number,
    y: number,
    w: number,
    h: number,
  ): void {
    const innerCircleD = Math.min(0.3 * w, 0.3 * h);

    c.setFillColor('#000000');

    c.begin();
    c.ellipse(x + (w - innerCircleD) / 2, y + (h - innerCircleD) / 2, innerCircleD, innerCircleD);
    c.fill();
    c.end();

    c.begin();
    c.ellipse(
      x + (w - innerCircleD * 2) / 2,
      y + (h - innerCircleD * 2) / 2,
      innerCircleD * 2,
      innerCircleD * 2,
    );
    c.stroke();
    c.end();
  }

  protected getFillColor(): string {
    return getNotEmpty(
      this.style[styleHighlightColor()],
      this.tryGetActuatorColor(),
      this.tryGetProcessVariableColor(),
    );
  }
}
