import { exist, it } from '@workbench/common/utils/logical-utility';
import { mxConstants, mxEllipse, mxgraph } from '@workbench/dts/mxg';
import {
  styleGlowColor,
  styleHighlightColor,
  styleObjectiveCategory,
  styleOutlineColor,
} from '../extensions/custom-style';
import { paintShapeGlow } from './shape-glow';

export class TargetShape extends mxEllipse {
  public readonly constraints: mxgraph.mxConnectionConstraint[] = [];

  public paintVertexShape(
    c: mxgraph.mxSvgCanvas2D,
    x: number,
    y: number,
    w: number,
    h: number,
  ): void {
    const glowColor = this.style[styleGlowColor()];
    const objectiveCategory = this.style[styleObjectiveCategory()];
    const outlineColor = this.style[styleOutlineColor()];

    if (glowColor) {
      paintShapeGlow(c, x, y, w, h, glowColor);
    }

    if (outlineColor) {
      c.setFillColor(outlineColor);
      c.setFillAlpha(1);
      c.setStrokeAlpha(0);
      super.paintVertexShape(c, x, y, w, h);
    }

    const d = 16;
    const dx = w / 2 - d / 2;
    const dy = h / 2 - d / 2;
    const textHeight = 12;

    c.setFillAlpha(1);
    c.setFillColor(this.style[styleHighlightColor()] ?? this.style[mxConstants.STYLE_FILLCOLOR]);
    c.setStrokeAlpha(1);
    c.setStrokeColor(this.style[mxConstants.STYLE_STROKECOLOR]);
    c.setStrokeWidth(this.style[mxConstants.STYLE_STROKEWIDTH]);

    if (it(exist(() => objectiveCategory))) {
      c.text(
        x,
        y + h / 2 - textHeight / 2,
        w,
        h,
        this.getLabel(objectiveCategory),
        mxConstants.ALIGN_CENTER,
        mxConstants.ALIGN_CENTER,
        null,
        null,
        null,
        null,
        null,
        null,
      );
    }

    super.paintVertexShape(c, x + dx, y + dy, d, d);
  }

  private getLabel(category: number): string {
    // prettier-ignore
    switch (category) {
      case 0: return 'S';
      case 1: return 'P';
      case 2: return 'E';
      default: return '';
    }
  }
}
