export class MainFunction {
  public mfmGroupId: number = null;
  // Link to original function
  public sourceId: string = null;
  // Link to original function
  public targetId: string = null;
}

export const clone = (src: MainFunction): MainFunction | null =>
  src
    ? Object.assign(new MainFunction(), {
        mfmGroupId: src.mfmGroupId,
        sourceId: src.sourceId,
        targetId: src.targetId,
      })
    : null;
