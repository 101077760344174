import { mxgraph, mxShape } from '@workbench/dts/mxg';

export class MainEquipmentShape extends mxShape {
  public paintVertexShape(
    c: mxgraph.mxSvgCanvas2D,
    x: number,
    y: number,
    w: number,
    h: number,
  ): void {
    c.begin();
    c.setFillColor('none');
    c.setStrokeColor('#A1EFB2');
    c.setStrokeWidth(3);
    c.roundrect(x, y, w, h, 25, 25);
    c.fillAndStroke();
    c.end();
  }
}
