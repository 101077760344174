import {
  CausalAnalysisPropagationPath,
  CausalAnalysisPropagationPathNode,
} from '@workbench/common/models/causal-reasoning-path.model';
import { and, equal, exist, it, not, or, Predicate } from '@workbench/common/utils/logical-utility';

/**
 * Filters an array of cause-consequence pathways based on filter criteria.
 *
 * @param list An array of propagation pathways received during Causal Analysis.
 * @param filter A filter that should be applied.
 * @returns An array of nodes that are entering the filtered propagation pathways.
 */
export const getCausalTreeNodes = (
  list: CausalAnalysisPropagationPath[],
  filter?: Partial<{
    rootCause: CausalAnalysisPropagationPathNode;
    endConsequence: CausalAnalysisPropagationPathNode;
  }>,
): CausalAnalysisPropagationPathNode[] =>
  getCausalTree(list, filter)
    // Select nodes only (excluding root causes and end consequences)
    .map(x => x.nodes)
    // Flatten all nodes to one array
    .reduce((result, path) => [...result, ...path], []);

/**
 * Filters an array of cause-consequence pathways based on filter criteria.
 *
 * @param list An array of propagation pathways received during Causal Analysis
 * @param filter A filter that should be applied
 * @returns a filtered array of propagation pathways
 */
export const getCausalTree = (
  list: CausalAnalysisPropagationPath[],
  filter?: Partial<{
    rootCause: CausalAnalysisPropagationPathNode;
    endConsequence: CausalAnalysisPropagationPathNode;
  }>,
): CausalAnalysisPropagationPath[] => {
  const hasRootCauseCriteria = exist(() => filter.rootCause);
  const hasEndConsequenceCriteria = exist(() => filter.endConsequence);

  return list.filter(x =>
    it(
      or(not(hasRootCauseCriteria), itemsEqual(x.rootCause, filter.rootCause)),
      or(not(hasEndConsequenceCriteria), itemsEqual(x.endConsequence, filter.endConsequence)),
    ),
  );
};

function itemsEqual(
  a: CausalAnalysisPropagationPathNode,
  b: CausalAnalysisPropagationPathNode,
): Predicate {
  return and(
    equal(
      () => a.functionId,
      () => b.functionId,
    ),
    equal(
      () => a.state,
      () => b.state,
    ),
  );
}
