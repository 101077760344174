import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { NotificationComponent } from '@workbench/shared/templates/notification/notification.component';
import { concatMap, Subject, tap } from 'rxjs';

interface Notification {
  message: string;
  title: string;
  userInteractionRequired: boolean;
}

@Injectable()
export class NotificationService {
  private readonly messageSource = new Subject<Notification>();
  private counter = 0;

  constructor(private readonly snackbar: MatSnackBar) {
    this.messageSource
      .pipe(
        tap(() => this.counter++),
        concatMap(notification => this.showNotification(notification).afterDismissed()),
      )
      .subscribe(() => {
        this.counter--;
      });
  }

  public error(message: string): void {
    this.message(message, 'Error', true);
  }

  public info(message: string): void {
    this.message(message, 'Information', false);
  }

  public message(message: string, title: string, userInteractionRequired = false): void {
    this.messageSource.next({ message, title, userInteractionRequired });
  }

  private showNotification(
    notification: Notification,
  ): MatSnackBarRef<NotificationComponent> | never {
    if (notification.userInteractionRequired === true) {
      return this.snackbar.openFromComponent(NotificationComponent, {
        data: {
          message: notification.message,
          numOfMessages: this.counter,
          title: notification.title,
        },
        horizontalPosition: 'center',
        panelClass: 'wb-notification',
        verticalPosition: 'top',
      });
    }
    if (notification.userInteractionRequired === false) {
      return this.snackbar.openFromComponent(NotificationComponent, {
        data: {
          duration: 4000,
          message: notification.message,
          numOfMessages: this.counter,
          title: notification.title,
        },
        horizontalPosition: 'center',
        panelClass: 'wb-notification',
        verticalPosition: 'top',
      });
    }
    throw new Error(`Argument out of range: ${notification}`);
  }
}
