import {
  isOfflineProcessVariable,
  isVirtualProcessVariable,
  processVariableValue,
} from '@workbench/common/types/process-variable-value.type';
import { it } from '@workbench/common/utils/logical-utility';
import { mxConstants, mxgraph, mxPoint, mxShape, mxUtils } from '@workbench/dts/mxg';
import {
  styleGlowColor,
  styleHighlightColor,
  styleOutlineColor,
  styleProcessVariable,
} from '../extensions/custom-style';
import { processVariableStyleColor } from './colors';
import { paintShapeGlow } from './shape-glow';

export abstract class ControlFunctionShape extends mxShape {
  public readonly constraints = [];

  public paintVertexShape(
    c: mxgraph.mxSvgCanvas2D,
    x: number,
    y: number,
    w: number,
    h: number,
  ): void {
    const arcSize =
      mxUtils.getValue(this.style, mxConstants.STYLE_ARCSIZE, mxConstants.LINE_ARCSIZE) / 2;
    const padding = 2;
    const glowColor = this.style[styleGlowColor()];
    const outlineColor = this.style[styleOutlineColor()];
    const processVariable = processVariableValue(this.style[styleProcessVariable()]);
    const processVariableColor = processVariableStyleColor(processVariable);

    if (glowColor) {
      paintShapeGlow(c, x, y, w, h, glowColor);
    }
    if (outlineColor) {
      const d = 10;

      c.setFillColor(outlineColor);
      c.setFillAlpha(1);
      c.setStrokeAlpha(0);
      c.begin();
      this.addPoints(
        c,
        [
          new mxPoint(x + padding - d * 0.9, y - d),
          new mxPoint(x + w - padding + d * 0.9, y - d),
          new mxPoint(x + w - padding + d * 0.9, y + h * 0.65 + d * 0.3),
          new mxPoint(x + 0.5 * w, y + h - padding + d),
          new mxPoint(x + padding - d * 0.9, y + h * 0.65 + d * 0.3),
        ],
        this.isRounded,
        arcSize,
        true,
        null,
        null,
      );
      c.fill();
    }

    c.begin();
    this.addPoints(
      c,
      [
        new mxPoint(x + padding, y),
        new mxPoint(x + w - padding, y),
        new mxPoint(x + w - padding, y + h * 0.65),
        new mxPoint(x + 0.5 * w, y + h - padding),
        new mxPoint(x + padding, y + h * 0.65),
      ],
      this.isRounded,
      arcSize,
      true,
      null,
      null,
    );
    c.setStrokeAlpha(1);
    c.setStrokeWidth(this.style[mxConstants.STYLE_STROKEWIDTH]);
    // prettier-ignore
    c.setFillColor(this.style[styleHighlightColor()] ?? processVariableColor ?? this.style[mxConstants.STYLE_FILLCOLOR]);
    c.setStrokeColor(this.style[mxConstants.STYLE_STROKECOLOR]);
    c.fillAndStroke();

    if (it(isVirtualProcessVariable(processVariable))) {
      const d = 12;

      c.setFillColor(processVariableColor);
      c.setFillAlpha(1);
      c.begin();
      c.ellipse(x + w - d + 3, y + h - d + 4, d, d);
      c.fillAndStroke();
    }
    if (it(isOfflineProcessVariable(processVariable))) {
      const d = 16;

      c.setFillColor('#FFFFFF');
      c.setStrokeColor(this.style[mxConstants.STYLE_STROKECOLOR]);
      c.setStrokeWidth(this.style[mxConstants.STYLE_STROKEWIDTH]);
      c.begin();
      c.ellipse(x + w / 2 - d / 2, y + h / 2 - d / 2 - 3, d, d);
      c.fill();
    }
  }

  protected paintLabel(
    c: mxgraph.mxSvgCanvas2D,
    x: number,
    y: number,
    w: number,
    h: number,
    label: string,
  ): void {
    c.setFontColor('#000000');
    c.setFontSize(20);
    c.setFontStyle(mxConstants.FONT_BOLD);
    c.fillAndStroke();
    // prettier-ignore
    c.text(x + w / 2, y + 0.25 * h, 0, 0, label, 'center', 'middle', false, '', null, false, 0, null);
  }
}
