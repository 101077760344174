import { Store } from '@ngrx/store';
import { CopyItem } from '@workbench/business-logic/models/copy-item.model';
import { getStyleObject } from '@workbench/business-logic/services/utility';
import { toMap } from '@workbench/common/utils/array-util';
import { jsonTryParse } from '@workbench/common/utils/json-util';
import { and, emptyStr, exist, idt, it, not, or } from '@workbench/common/utils/logical-utility';
import {
  addLabelToEdge,
  getConcept,
  getLabel,
  updateSubModel,
} from '@workbench/common/utils/mx-graph-util';
import { getNotEmpty } from '@workbench/common/utils/string-util';
import { GeometryPointsPositionStrategy } from '@workbench/dts/enums/geometry-points-position-strategy';
import { mxConstants, mxGeometry, mxgraph, mxPoint, mxUtils } from '@workbench/dts/mxg';
import {
  isConditionRelation,
  isControlRelation,
  isMeansEndRelation,
  isRelation,
  isStructure,
  isSUB,
} from '@workbench/multilevel-flow-modeling/core/mfm-core';
import { CellBuilder } from '@workbench/mx-graph/cell-builder';
import { MfmConceptConfiguration } from '@workbench/state/model-builder/mfm-model.model';
import {
  copySelection,
  pasteSelection,
} from '@workbench/state/model-builder/model-builder.actions';
import { JsonConvert, OperationMode } from 'json2typescript';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { equal } from '../../common/utils/logical-utility';
import { MainFunction } from '../models/main-function.model';
import { clone, MfmModel } from '../models/mfm.model';

interface MxClipboard {
  copy: (graph: mxgraph.mxGraph) => void;
  setCells: (arg0: CopyItem[]) => void;
  paste: (graph: mxgraph.mxGraph) => void;
}

interface EditorUiInterface {
  prototype: { updatePasteActionStates: () => void };
}

declare let mxClipboard: MxClipboard;
declare let EditorUi: EditorUiInterface;

export function copy(cells: mxgraph.mxCell[], graph: mxgraph.mxGraph): CopyItem[] {
  const rootCell = graph.getDefaultParent();

  cells.forEach(cell => {
    if (cell.isEdge()) {
      if (cell.parent.id === rootCell.id) {
        if (CellsClipboardService.isCellInStructure(cell)) {
          cell.geometry.pointsPositionStrategy =
            GeometryPointsPositionStrategy.CountPositionWithDelta;
        } else {
          cell.geometry.pointsPositionStrategy =
            GeometryPointsPositionStrategy.CountPositionFromRoot;
        }
      } else {
        if (CellsClipboardService.isParentCopyingWithCell(cell.parent.id, cells)) {
          cell.geometry.pointsPositionStrategy =
            GeometryPointsPositionStrategy.CountPositionFromParent;
        } else {
          cell.geometry.pointsPositionStrategy =
            GeometryPointsPositionStrategy.CountPositionFromRoot;
        }
      }
    }
  });

  return CellsClipboardService.cellsToCopyItems(graph, cells);
}

export function copyItemsToCells(
  graph: mxgraph.mxGraph,
  copyItemList: CopyItem[],
  original: Map<string, MfmConceptConfiguration>,
  point: { x: number; y: number } = { x: 0, y: 0 },
): mxgraph.mxCell[] {
  graph.getModel().beginUpdate();
  const addedToGraphCellList: mxgraph.mxCell[] = [];

  // Get the coordinate of top-left corner of copied piece
  const [left, top] = copyItemList.reduce(
    ([x, y], { mfm, geometry }) => {
      if (
        it(
          or(
            isRelation(() => mfm.conceptId),
            () => mfm.parentId !== '',
          ),
        )
      ) {
        return [x, y];
      }

      return [Math.min(x, geometry.x), Math.min(y, geometry.y)];
    },
    [Number.MAX_SAFE_INTEGER, Number.MAX_SAFE_INTEGER],
  );

  try {
    const builder = new CellBuilder();

    copyItemList.forEach(copyItem => {
      const addedCell = builder.create(copyItem.mfm.conceptId);

      graph.addCell(addedCell, null);

      addedCell.copyItem = copyItem;

      const styleOld = getStyleObject(copyItem.style);
      const newStyle = mxUtils.setStyle(
        addedCell.getStyle(),
        mxConstants.STYLE_ROTATION,
        styleOld[mxConstants.STYLE_ROTATION],
      );

      addedCell.setStyle(newStyle);

      const parent = CellsClipboardService.getClonedCellByOriginalId(
        addedToGraphCellList,
        addedCell.copyItem.mfm.parentId,
      );

      graph.addCell(addedCell, parent);

      CellsClipboardService.updateGeometryAfterClipboardPaste(
        addedCell,
        copyItem.geometry,
        point.x - left,
        point.y - top,
      );

      addedToGraphCellList.push(addedCell);
    });

    // We need to collect all terminals of the cloned sub-models,
    // so that we can find the ends of the relations that are connected to them.
    const subModelTerminals = [];

    addedToGraphCellList.forEach(clonedCell => {
      clonedCell.mfm = clone(clonedCell.copyItem.mfm);
      clonedCell.mfm.id = clonedCell.getId().toString();
      clonedCell.mfm.parentId =
        clonedCell.parent !== graph.getDefaultParent() ? clonedCell.parent?.getId() ?? '' : '';
      clonedCell.mfm.linkSourceId = clonedCell.getTerminal(true)?.getId().toString() ?? '';
      clonedCell.mfm.linkTargetId = clonedCell.getTerminal(false)?.getId().toString() ?? '';
      // To recover all the connections, we need to add the sub-model terminals in stuff.
      // We need to create child cells first and add them to the graph as children to the cloned sub-model.
      // As follows, the relations that are connected to the sub-model terminals will be connected properly.
      if (it(isSUB(() => clonedCell.mfm.conceptId))) {
        const originalSubModelId: string = clonedCell.copyItem.mfm.id;

        // Create the sub-model terminals
        updateSubModel(graph, clonedCell, {
          next: original.get(originalSubModelId).subModelTerminals,
        });

        // Add the sub-model terminals to the `addedToGraphCellList`, so that
        // the ends of the relations will be found.
        // And add the `copyItem` with the original `id` to the terminal before that.
        graph.getChildVertices(clonedCell).forEach(terminal => {
          // The terminal's `id` is consists of the sub-model's `id` and the terminal's `id` divided by a dot.
          // S.N1.N2...Nn, where S is the sub-model `id` and N1.N2...Nn is a compound `id` of the terminal.
          // We need to substitute the part of the `id` that belongs to the sub-model with the original sub-model id,
          // so that this terminal will be found later by the `getClonedCellByOriginalId` function.
          const terminalId = terminal.mfm.id.split('.').slice(1).join('.');

          terminal.copyItem = { mfm: { id: `${originalSubModelId}.${terminalId}` } };

          subModelTerminals.push(terminal);
        });
      }
    });

    CellsClipboardService.updateLinks([...addedToGraphCellList, ...subModelTerminals], graph);
  } catch (error) {
    console.log(error);
  } finally {
    graph.getModel().endUpdate();
  }

  return addedToGraphCellList;
}

export function deserializeCells(
  jsonStr: string,
): Observable<{ cells: CopyItem[]; original: Map<string, MfmConceptConfiguration> }> {
  return jsonTryParse<{ cells: CopyItem[]; original: MfmConceptConfiguration[] }>(jsonStr).pipe(
    map(({ cells, original }) => {
      const jsonConvert = new JsonConvert(OperationMode.DISABLE);

      jsonConvert.ignoreRequiredCheck = true;
      const copyItemList: CopyItem[] = jsonConvert.deserializeArray(cells, CopyItem);

      return { cells: copyItemList, original: toMap(original, 'id') };
    }),
  );
}

export class CellsClipboardService {
  constructor(private readonly store: Store) {}

  public static getClonedCellByOriginalId(
    clonedCells: mxgraph.mxCell[],
    cellId: string,
  ): mxgraph.mxCell | null {
    if (cellId != null) {
      return clonedCells.find(cell => cell.copyItem.mfm.id === cellId.toString()) ?? null;
    }

    return null;
  }

  public static updateLinks(clonedCells: mxgraph.mxCell[], graph: mxgraph.mxGraph): void {
    clonedCells
      .filter(cell => cell.isEdge())
      .forEach(clonedCell => {
        const copyItem: CopyItem = clonedCell.copyItem;
        const oldMainFunction = copyItem.mfm.mainFunction;

        clonedCell.mfm.mainFunction = null;
        graph.getModel().setStyle(clonedCell, copyItem.style);

        const source = this.getClonedCellByOriginalId(clonedCells, copyItem.mfm.linkSourceId);
        const target = this.getClonedCellByOriginalId(clonedCells, copyItem.mfm.linkTargetId);

        if (oldMainFunction) {
          const newMainFunction = new MainFunction();

          newMainFunction.mfmGroupId = oldMainFunction.mfmGroupId;
          if (oldMainFunction.sourceId != null) {
            newMainFunction.sourceId =
              this.getClonedCellByOriginalId(
                clonedCells,
                oldMainFunction.sourceId,
              )?.id?.toString() ?? null;
          }
          if (oldMainFunction.targetId != null) {
            newMainFunction.targetId =
              this.getClonedCellByOriginalId(
                clonedCells,
                oldMainFunction.targetId,
              )?.id?.toString() ?? null;
          }

          clonedCell.mfm.mainFunction = newMainFunction;

          if (source && target) {
            if (
              it(
                and(
                  exist(() => clonedCell.mfm.mainFunction.targetId),
                  or(
                    isConditionRelation(getConcept(clonedCell)),
                    isControlRelation(getConcept(clonedCell)),
                  ),
                ),
              )
            ) {
              const labelTarget = graph.getModel().getCell(clonedCell.mfm.mainFunction.targetId);

              addLabelToEdge(clonedCell, graph, getLabel(labelTarget), false);
            }

            if (
              it(
                and(
                  exist(() => clonedCell.mfm.mainFunction.sourceId),
                  isMeansEndRelation(getConcept(clonedCell)),
                ),
              )
            ) {
              const labelSource = graph.getModel().getCell(clonedCell.mfm.mainFunction.sourceId);

              addLabelToEdge(clonedCell, graph, getLabel(labelSource), true);
            }
          }
        }
        graph.getModel().setTerminals(clonedCell, source, target);
      });
  }

  public static isCellInStructure(cell: mxgraph.mxCell): boolean {
    return it(
      exist(
        () => cell.source?.parent?.id,
        () => cell.target?.parent?.id,
      ),
      equal(
        () => cell.source.parent.id,
        () => cell.target.parent.id,
      ),
      or(
        isStructure(() => cell.source.parent.mfm?.conceptId),
        isStructure(() => cell.target.parent.mfm?.conceptId),
      ),
    );
  }

  public static isParentCopyingWithCell(
    parentId: string,
    selectedCells: mxgraph.mxCell[],
  ): boolean {
    return selectedCells.some(cell => cell.id === parentId);
  }

  public static cellsToCopyItems(graph: mxgraph.mxGraph, cells: mxgraph.mxCell[]): CopyItem[] {
    const copyItemList = cells
      .filter(cell => it(exist(() => cell.mfm)))
      .map(cell => CellsClipboardService.createCopyItemByCell(cell, graph.getDefaultParent()));

    // resolve situation when copy child without parent
    copyItemList
      .filter(copyItem =>
        it(
          () => getNotEmpty(copyItem.mfm.parentId) !== '',
          () => copyItemList.every(item => item.mfm.id !== copyItem.mfm.parentId),
        ),
      )
      .forEach(copyItem => {
        const parentCell = graph.getModel().getCell(copyItem.mfm.parentId);

        copyItem.mfm.parentId = '';
        copyItem.geometry.x += parentCell.geometry.x;
        copyItem.geometry.y += parentCell.geometry.y;
      });

    return copyItemList;
  }

  public static createCopyItemByCell(cell: mxgraph.mxCell, root: mxgraph.mxCell): CopyItem {
    const copyItem: CopyItem = new CopyItem();

    copyItem.style = cell.style;
    copyItem.mfm = CellsClipboardService.getUpdatedModelByCell(cell, root);

    const newGeometry: mxgraph.mxGeometry = new mxGeometry(
      cell.geometry.x,
      cell.geometry.y,
      cell.geometry.width,
      cell.geometry.height,
    );

    newGeometry.points =
      cell.geometry.points?.map((point: mxgraph.mxPoint) => point.clone()) ?? null;
    newGeometry.pointsPositionStrategy = cell.geometry.pointsPositionStrategy;
    newGeometry.sourcePoint = cell.geometry.sourcePoint?.clone() ?? null;
    newGeometry.targetPoint = cell.geometry.targetPoint?.clone() ?? null;

    copyItem.geometry = newGeometry;

    return copyItem;
  }

  public static getUpdatedModelByCell(cell: mxgraph.mxCell, root: mxgraph.mxCell): MfmModel {
    if (it(not(exist(idt(cell.mfm))))) {
      return null;
    }
    const mfmModel = clone(cell.mfm);

    mfmModel.id = cell.getId().toString();

    if (it(isSUB(getConcept(cell)))) {
      // TODO: Do we need this? I don't remember why it was added
      // mfmModel.description = cell.mfm.description;
      cell.setValue(mfmModel.label);
    } else {
      mfmModel.parentId = cell.parent && cell.parent !== root ? cell.parent.getId().toString() : '';
      // source
      const source = cell.getTerminal(true);

      mfmModel.linkSourceId = source ? source.getId().toString() : null;
      // target
      const target = cell.getTerminal(false);

      mfmModel.linkTargetId = target ? target.getId().toString() : null;
    }

    return mfmModel;
  }

  public static updateGeometryAfterClipboardPaste(
    cell: mxgraph.mxCell,
    geometry: mxgraph.mxGeometry,
    deltaX: number,
    deltaY: number,
  ): void {
    // set position
    if (cell.isEdge()) {
      cell.geometry.sourcePoint = new mxPoint(
        geometry.sourcePoint.x + deltaX,
        geometry.sourcePoint.y + deltaY,
      );
      cell.geometry.targetPoint = new mxPoint(
        geometry.targetPoint.x + deltaX,
        geometry.targetPoint.y + deltaY,
      );
      cell.geometry.points = geometry.points
        ? geometry.points.map((point: mxgraph.mxPoint) =>
            CellsClipboardService.createPoint(point, geometry, deltaX, deltaY),
          )
        : null;
    } else {
      cell.geometry.x = geometry.x;
      cell.geometry.y = geometry.y;
    }

    if (it(isStructure(getConcept(cell)))) {
      cell.geometry.width = geometry.width;
      cell.geometry.height = geometry.height;
    }

    if (
      it(
        equal(emptyStr, () => cell.mfm.parentId ?? ''),
        not(isRelation(() => cell.mfm.conceptId)),
      )
    ) {
      cell.geometry.x = geometry.x + deltaX;
      cell.geometry.y = geometry.y + deltaY;
    }
  }

  public static createPoint(
    point: mxgraph.mxPoint,
    geometry: mxgraph.mxGeometry,
    deltaX: number,
    deltaY: number,
  ): mxgraph.mxPoint {
    switch (geometry.pointsPositionStrategy) {
      case GeometryPointsPositionStrategy.CountPositionWithDelta: {
        return new mxPoint(point.x + deltaX, point.y + deltaY);
      }
      case GeometryPointsPositionStrategy.CountPositionFromParent: {
        return new mxPoint(point.x, point.y);
      }
      case GeometryPointsPositionStrategy.CountPositionFromRoot: {
        return new mxPoint(geometry.x + point.x + deltaX, geometry.y + point.y + deltaY);
      }
    }
  }

  public initMxClipboard(): void {
    this.enableModelCopy(true);

    mxClipboard.copy = (): void => {
      this.store.dispatch(copySelection());
    };
    mxClipboard.paste = (): void => {
      this.store.dispatch(pasteSelection());
    };
  }

  public enableModelCopy(isEnabled: boolean): void {
    EditorUi.prototype.updatePasteActionStates = function (): void {
      const paste = this.actions.get('paste');

      // it allows us to paste anytime
      paste.setEnabled(isEnabled);
    };
  }
}
