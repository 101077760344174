function n(n) {
  for (var t = arguments.length, r = Array(t > 1 ? t - 1 : 0), e = 1; e < t; e++) r[e - 1] = arguments[e];
  if ("production" !== process.env.NODE_ENV) {
    var i = Y[n],
      o = i ? "function" == typeof i ? i.apply(null, r) : i : "unknown error nr: " + n;
    throw Error("[Immer] " + o);
  }
  throw Error("[Immer] minified error nr: " + n + (r.length ? " " + r.map(function (n) {
    return "'" + n + "'";
  }).join(",") : "") + ". Find the full error at: https://bit.ly/3cXEKWf");
}
function t(n) {
  return !!n && !!n[Q];
}
function r(n) {
  return !!n && (function (n) {
    if (!n || "object" != typeof n) return !1;
    var t = Object.getPrototypeOf(n);
    if (null === t) return !0;
    var r = Object.hasOwnProperty.call(t, "constructor") && t.constructor;
    return r === Object || "function" == typeof r && Function.toString.call(r) === Z;
  }(n) || Array.isArray(n) || !!n[L] || !!n.constructor[L] || s(n) || v(n));
}
function e(r) {
  return t(r) || n(23, r), r[Q].t;
}
function i(n, t, r) {
  void 0 === r && (r = !1), 0 === o(n) ? (r ? Object.keys : nn)(n).forEach(function (e) {
    r && "symbol" == typeof e || t(e, n[e], n);
  }) : n.forEach(function (r, e) {
    return t(e, r, n);
  });
}
function o(n) {
  var t = n[Q];
  return t ? t.i > 3 ? t.i - 4 : t.i : Array.isArray(n) ? 1 : s(n) ? 2 : v(n) ? 3 : 0;
}
function u(n, t) {
  return 2 === o(n) ? n.has(t) : Object.prototype.hasOwnProperty.call(n, t);
}
function a(n, t) {
  return 2 === o(n) ? n.get(t) : n[t];
}
function f(n, t, r) {
  var e = o(n);
  2 === e ? n.set(t, r) : 3 === e ? (n.delete(t), n.add(r)) : n[t] = r;
}
function c(n, t) {
  return n === t ? 0 !== n || 1 / n == 1 / t : n != n && t != t;
}
function s(n) {
  return X && n instanceof Map;
}
function v(n) {
  return q && n instanceof Set;
}
function p(n) {
  return n.o || n.t;
}
function l(n) {
  if (Array.isArray(n)) return Array.prototype.slice.call(n);
  var t = tn(n);
  delete t[Q];
  for (var r = nn(t), e = 0; e < r.length; e++) {
    var i = r[e],
      o = t[i];
    !1 === o.writable && (o.writable = !0, o.configurable = !0), (o.get || o.set) && (t[i] = {
      configurable: !0,
      writable: !0,
      enumerable: o.enumerable,
      value: n[i]
    });
  }
  return Object.create(Object.getPrototypeOf(n), t);
}
function d(n, e) {
  return void 0 === e && (e = !1), y(n) || t(n) || !r(n) ? n : (o(n) > 1 && (n.set = n.add = n.clear = n.delete = h), Object.freeze(n), e && i(n, function (n, t) {
    return d(t, !0);
  }, !0), n);
}
function h() {
  n(2);
}
function y(n) {
  return null == n || "object" != typeof n || Object.isFrozen(n);
}
function b(t) {
  var r = rn[t];
  return r || n(18, t), r;
}
function m(n, t) {
  rn[n] || (rn[n] = t);
}
function _() {
  return "production" === process.env.NODE_ENV || U || n(0), U;
}
function j(n, t) {
  t && (b("Patches"), n.u = [], n.s = [], n.v = t);
}
function O(n) {
  g(n), n.p.forEach(S), n.p = null;
}
function g(n) {
  n === U && (U = n.l);
}
function w(n) {
  return U = {
    p: [],
    l: U,
    h: n,
    m: !0,
    _: 0
  };
}
function S(n) {
  var t = n[Q];
  0 === t.i || 1 === t.i ? t.j() : t.O = !0;
}
function P(t, e) {
  e._ = e.p.length;
  var i = e.p[0],
    o = void 0 !== t && t !== i;
  return e.h.g || b("ES5").S(e, t, o), o ? (i[Q].P && (O(e), n(4)), r(t) && (t = M(e, t), e.l || x(e, t)), e.u && b("Patches").M(i[Q], t, e.u, e.s)) : t = M(e, i, []), O(e), e.u && e.v(e.u, e.s), t !== H ? t : void 0;
}
function M(n, t, r) {
  if (y(t)) return t;
  var e = t[Q];
  if (!e) return i(t, function (i, o) {
    return A(n, e, t, i, o, r);
  }, !0), t;
  if (e.A !== n) return t;
  if (!e.P) return x(n, e.t, !0), e.t;
  if (!e.I) {
    e.I = !0, e.A._--;
    var o = 4 === e.i || 5 === e.i ? e.o = l(e.k) : e.o;
    i(3 === e.i ? new Set(o) : o, function (t, i) {
      return A(n, e, o, t, i, r);
    }), x(n, o, !1), r && n.u && b("Patches").R(e, r, n.u, n.s);
  }
  return e.o;
}
function A(e, i, o, a, c, s) {
  if ("production" !== process.env.NODE_ENV && c === o && n(5), t(c)) {
    var v = M(e, c, s && i && 3 !== i.i && !u(i.D, a) ? s.concat(a) : void 0);
    if (f(o, a, v), !t(v)) return;
    e.m = !1;
  }
  if (r(c) && !y(c)) {
    if (!e.h.F && e._ < 1) return;
    M(e, c), i && i.A.l || x(e, c);
  }
}
function x(n, t, r) {
  void 0 === r && (r = !1), n.h.F && n.m && d(t, r);
}
function z(n, t) {
  var r = n[Q];
  return (r ? p(r) : n)[t];
}
function I(n, t) {
  if (t in n) for (var r = Object.getPrototypeOf(n); r;) {
    var e = Object.getOwnPropertyDescriptor(r, t);
    if (e) return e;
    r = Object.getPrototypeOf(r);
  }
}
function k(n) {
  n.P || (n.P = !0, n.l && k(n.l));
}
function E(n) {
  n.o || (n.o = l(n.t));
}
function R(n, t, r) {
  var e = s(t) ? b("MapSet").N(t, r) : v(t) ? b("MapSet").T(t, r) : n.g ? function (n, t) {
    var r = Array.isArray(n),
      e = {
        i: r ? 1 : 0,
        A: t ? t.A : _(),
        P: !1,
        I: !1,
        D: {},
        l: t,
        t: n,
        k: null,
        o: null,
        j: null,
        C: !1
      },
      i = e,
      o = en;
    r && (i = [e], o = on);
    var u = Proxy.revocable(i, o),
      a = u.revoke,
      f = u.proxy;
    return e.k = f, e.j = a, f;
  }(t, r) : b("ES5").J(t, r);
  return (r ? r.A : _()).p.push(e), e;
}
function D(e) {
  return t(e) || n(22, e), function n(t) {
    if (!r(t)) return t;
    var e,
      u = t[Q],
      c = o(t);
    if (u) {
      if (!u.P && (u.i < 4 || !b("ES5").K(u))) return u.t;
      u.I = !0, e = F(t, c), u.I = !1;
    } else e = F(t, c);
    return i(e, function (t, r) {
      u && a(u.t, t) === r || f(e, t, n(r));
    }), 3 === c ? new Set(e) : e;
  }(e);
}
function F(n, t) {
  switch (t) {
    case 2:
      return new Map(n);
    case 3:
      return Array.from(n);
  }
  return l(n);
}
function N() {
  function r(n, t) {
    var r = s[n];
    return r ? r.enumerable = t : s[n] = r = {
      configurable: !0,
      enumerable: t,
      get: function () {
        var t = this[Q];
        return "production" !== process.env.NODE_ENV && f(t), en.get(t, n);
      },
      set: function (t) {
        var r = this[Q];
        "production" !== process.env.NODE_ENV && f(r), en.set(r, n, t);
      }
    }, r;
  }
  function e(n) {
    for (var t = n.length - 1; t >= 0; t--) {
      var r = n[t][Q];
      if (!r.P) switch (r.i) {
        case 5:
          a(r) && k(r);
          break;
        case 4:
          o(r) && k(r);
      }
    }
  }
  function o(n) {
    for (var t = n.t, r = n.k, e = nn(r), i = e.length - 1; i >= 0; i--) {
      var o = e[i];
      if (o !== Q) {
        var a = t[o];
        if (void 0 === a && !u(t, o)) return !0;
        var f = r[o],
          s = f && f[Q];
        if (s ? s.t !== a : !c(f, a)) return !0;
      }
    }
    var v = !!t[Q];
    return e.length !== nn(t).length + (v ? 0 : 1);
  }
  function a(n) {
    var t = n.k;
    if (t.length !== n.t.length) return !0;
    var r = Object.getOwnPropertyDescriptor(t, t.length - 1);
    return !(!r || r.get);
  }
  function f(t) {
    t.O && n(3, JSON.stringify(p(t)));
  }
  var s = {};
  m("ES5", {
    J: function (n, t) {
      var e = Array.isArray(n),
        i = function (n, t) {
          if (n) {
            for (var e = Array(t.length), i = 0; i < t.length; i++) Object.defineProperty(e, "" + i, r(i, !0));
            return e;
          }
          var o = tn(t);
          delete o[Q];
          for (var u = nn(o), a = 0; a < u.length; a++) {
            var f = u[a];
            o[f] = r(f, n || !!o[f].enumerable);
          }
          return Object.create(Object.getPrototypeOf(t), o);
        }(e, n),
        o = {
          i: e ? 5 : 4,
          A: t ? t.A : _(),
          P: !1,
          I: !1,
          D: {},
          l: t,
          t: n,
          k: i,
          o: null,
          O: !1,
          C: !1
        };
      return Object.defineProperty(i, Q, {
        value: o,
        writable: !0
      }), i;
    },
    S: function (n, r, o) {
      o ? t(r) && r[Q].A === n && e(n.p) : (n.u && function n(t) {
        if (t && "object" == typeof t) {
          var r = t[Q];
          if (r) {
            var e = r.t,
              o = r.k,
              f = r.D,
              c = r.i;
            if (4 === c) i(o, function (t) {
              t !== Q && (void 0 !== e[t] || u(e, t) ? f[t] || n(o[t]) : (f[t] = !0, k(r)));
            }), i(e, function (n) {
              void 0 !== o[n] || u(o, n) || (f[n] = !1, k(r));
            });else if (5 === c) {
              if (a(r) && (k(r), f.length = !0), o.length < e.length) for (var s = o.length; s < e.length; s++) f[s] = !1;else for (var v = e.length; v < o.length; v++) f[v] = !0;
              for (var p = Math.min(o.length, e.length), l = 0; l < p; l++) void 0 === f[l] && n(o[l]);
            }
          }
        }
      }(n.p[0]), e(n.p));
    },
    K: function (n) {
      return 4 === n.i ? o(n) : a(n);
    }
  });
}
function T() {
  function e(n) {
    if (!r(n)) return n;
    if (Array.isArray(n)) return n.map(e);
    if (s(n)) return new Map(Array.from(n.entries()).map(function (n) {
      return [n[0], e(n[1])];
    }));
    if (v(n)) return new Set(Array.from(n).map(e));
    var t = Object.create(Object.getPrototypeOf(n));
    for (var i in n) t[i] = e(n[i]);
    return u(n, L) && (t[L] = n[L]), t;
  }
  function f(n) {
    return t(n) ? e(n) : n;
  }
  var c = "add";
  m("Patches", {
    $: function (t, r) {
      return r.forEach(function (r) {
        for (var i = r.path, u = r.op, f = t, s = 0; s < i.length - 1; s++) {
          var v = o(f),
            p = "" + i[s];
          0 !== v && 1 !== v || "__proto__" !== p && "constructor" !== p || n(24), "function" == typeof f && "prototype" === p && n(24), "object" != typeof (f = a(f, p)) && n(15, i.join("/"));
        }
        var l = o(f),
          d = e(r.value),
          h = i[i.length - 1];
        switch (u) {
          case "replace":
            switch (l) {
              case 2:
                return f.set(h, d);
              case 3:
                n(16);
              default:
                return f[h] = d;
            }
          case c:
            switch (l) {
              case 1:
                return "-" === h ? f.push(d) : f.splice(h, 0, d);
              case 2:
                return f.set(h, d);
              case 3:
                return f.add(d);
              default:
                return f[h] = d;
            }
          case "remove":
            switch (l) {
              case 1:
                return f.splice(h, 1);
              case 2:
                return f.delete(h);
              case 3:
                return f.delete(r.value);
              default:
                return delete f[h];
            }
          default:
            n(17, u);
        }
      }), t;
    },
    R: function (n, t, r, e) {
      switch (n.i) {
        case 0:
        case 4:
        case 2:
          return function (n, t, r, e) {
            var o = n.t,
              s = n.o;
            i(n.D, function (n, i) {
              var v = a(o, n),
                p = a(s, n),
                l = i ? u(o, n) ? "replace" : c : "remove";
              if (v !== p || "replace" !== l) {
                var d = t.concat(n);
                r.push("remove" === l ? {
                  op: l,
                  path: d
                } : {
                  op: l,
                  path: d,
                  value: p
                }), e.push(l === c ? {
                  op: "remove",
                  path: d
                } : "remove" === l ? {
                  op: c,
                  path: d,
                  value: f(v)
                } : {
                  op: "replace",
                  path: d,
                  value: f(v)
                });
              }
            });
          }(n, t, r, e);
        case 5:
        case 1:
          return function (n, t, r, e) {
            var i = n.t,
              o = n.D,
              u = n.o;
            if (u.length < i.length) {
              var a = [u, i];
              i = a[0], u = a[1];
              var s = [e, r];
              r = s[0], e = s[1];
            }
            for (var v = 0; v < i.length; v++) if (o[v] && u[v] !== i[v]) {
              var p = t.concat([v]);
              r.push({
                op: "replace",
                path: p,
                value: f(u[v])
              }), e.push({
                op: "replace",
                path: p,
                value: f(i[v])
              });
            }
            for (var l = i.length; l < u.length; l++) {
              var d = t.concat([l]);
              r.push({
                op: c,
                path: d,
                value: f(u[l])
              });
            }
            i.length < u.length && e.push({
              op: "replace",
              path: t.concat(["length"]),
              value: i.length
            });
          }(n, t, r, e);
        case 3:
          return function (n, t, r, e) {
            var i = n.t,
              o = n.o,
              u = 0;
            i.forEach(function (n) {
              if (!o.has(n)) {
                var i = t.concat([u]);
                r.push({
                  op: "remove",
                  path: i,
                  value: n
                }), e.unshift({
                  op: c,
                  path: i,
                  value: n
                });
              }
              u++;
            }), u = 0, o.forEach(function (n) {
              if (!i.has(n)) {
                var o = t.concat([u]);
                r.push({
                  op: c,
                  path: o,
                  value: n
                }), e.unshift({
                  op: "remove",
                  path: o,
                  value: n
                });
              }
              u++;
            });
          }(n, t, r, e);
      }
    },
    M: function (n, t, r, e) {
      r.push({
        op: "replace",
        path: [],
        value: t === H ? void 0 : t
      }), e.push({
        op: "replace",
        path: [],
        value: n.t
      });
    }
  });
}
function C() {
  function t(n, t) {
    function r() {
      this.constructor = n;
    }
    a(n, t), n.prototype = (r.prototype = t.prototype, new r());
  }
  function e(n) {
    n.o || (n.D = new Map(), n.o = new Map(n.t));
  }
  function o(n) {
    n.o || (n.o = new Set(), n.t.forEach(function (t) {
      if (r(t)) {
        var e = R(n.A.h, t, n);
        n.p.set(t, e), n.o.add(e);
      } else n.o.add(t);
    }));
  }
  function u(t) {
    t.O && n(3, JSON.stringify(p(t)));
  }
  var a = function (n, t) {
      return (a = Object.setPrototypeOf || {
        __proto__: []
      } instanceof Array && function (n, t) {
        n.__proto__ = t;
      } || function (n, t) {
        for (var r in t) t.hasOwnProperty(r) && (n[r] = t[r]);
      })(n, t);
    },
    f = function () {
      function n(n, t) {
        return this[Q] = {
          i: 2,
          l: t,
          A: t ? t.A : _(),
          P: !1,
          I: !1,
          o: void 0,
          D: void 0,
          t: n,
          k: this,
          C: !1,
          O: !1
        }, this;
      }
      t(n, Map);
      var o = n.prototype;
      return Object.defineProperty(o, "size", {
        get: function () {
          return p(this[Q]).size;
        }
      }), o.has = function (n) {
        return p(this[Q]).has(n);
      }, o.set = function (n, t) {
        var r = this[Q];
        return u(r), p(r).has(n) && p(r).get(n) === t || (e(r), k(r), r.D.set(n, !0), r.o.set(n, t), r.D.set(n, !0)), this;
      }, o.delete = function (n) {
        if (!this.has(n)) return !1;
        var t = this[Q];
        return u(t), e(t), k(t), t.D.set(n, !1), t.o.delete(n), !0;
      }, o.clear = function () {
        var n = this[Q];
        u(n), p(n).size && (e(n), k(n), n.D = new Map(), i(n.t, function (t) {
          n.D.set(t, !1);
        }), n.o.clear());
      }, o.forEach = function (n, t) {
        var r = this;
        p(this[Q]).forEach(function (e, i) {
          n.call(t, r.get(i), i, r);
        });
      }, o.get = function (n) {
        var t = this[Q];
        u(t);
        var i = p(t).get(n);
        if (t.I || !r(i)) return i;
        if (i !== t.t.get(n)) return i;
        var o = R(t.A.h, i, t);
        return e(t), t.o.set(n, o), o;
      }, o.keys = function () {
        return p(this[Q]).keys();
      }, o.values = function () {
        var n,
          t = this,
          r = this.keys();
        return (n = {})[V] = function () {
          return t.values();
        }, n.next = function () {
          var n = r.next();
          return n.done ? n : {
            done: !1,
            value: t.get(n.value)
          };
        }, n;
      }, o.entries = function () {
        var n,
          t = this,
          r = this.keys();
        return (n = {})[V] = function () {
          return t.entries();
        }, n.next = function () {
          var n = r.next();
          if (n.done) return n;
          var e = t.get(n.value);
          return {
            done: !1,
            value: [n.value, e]
          };
        }, n;
      }, o[V] = function () {
        return this.entries();
      }, n;
    }(),
    c = function () {
      function n(n, t) {
        return this[Q] = {
          i: 3,
          l: t,
          A: t ? t.A : _(),
          P: !1,
          I: !1,
          o: void 0,
          t: n,
          k: this,
          p: new Map(),
          O: !1,
          C: !1
        }, this;
      }
      t(n, Set);
      var r = n.prototype;
      return Object.defineProperty(r, "size", {
        get: function () {
          return p(this[Q]).size;
        }
      }), r.has = function (n) {
        var t = this[Q];
        return u(t), t.o ? !!t.o.has(n) || !(!t.p.has(n) || !t.o.has(t.p.get(n))) : t.t.has(n);
      }, r.add = function (n) {
        var t = this[Q];
        return u(t), this.has(n) || (o(t), k(t), t.o.add(n)), this;
      }, r.delete = function (n) {
        if (!this.has(n)) return !1;
        var t = this[Q];
        return u(t), o(t), k(t), t.o.delete(n) || !!t.p.has(n) && t.o.delete(t.p.get(n));
      }, r.clear = function () {
        var n = this[Q];
        u(n), p(n).size && (o(n), k(n), n.o.clear());
      }, r.values = function () {
        var n = this[Q];
        return u(n), o(n), n.o.values();
      }, r.entries = function () {
        var n = this[Q];
        return u(n), o(n), n.o.entries();
      }, r.keys = function () {
        return this.values();
      }, r[V] = function () {
        return this.values();
      }, r.forEach = function (n, t) {
        for (var r = this.values(), e = r.next(); !e.done;) n.call(t, e.value, e.value, this), e = r.next();
      }, n;
    }();
  m("MapSet", {
    N: function (n, t) {
      return new f(n, t);
    },
    T: function (n, t) {
      return new c(n, t);
    }
  });
}
function J() {
  N(), C(), T();
}
function K(n) {
  return n;
}
function $(n) {
  return n;
}
var G,
  U,
  W = "undefined" != typeof Symbol && "symbol" == typeof Symbol("x"),
  X = "undefined" != typeof Map,
  q = "undefined" != typeof Set,
  B = "undefined" != typeof Proxy && void 0 !== Proxy.revocable && "undefined" != typeof Reflect,
  H = W ? Symbol.for("immer-nothing") : ((G = {})["immer-nothing"] = !0, G),
  L = W ? Symbol.for("immer-draftable") : "__$immer_draftable",
  Q = W ? Symbol.for("immer-state") : "__$immer_state",
  V = "undefined" != typeof Symbol && Symbol.iterator || "@@iterator",
  Y = {
    0: "Illegal state",
    1: "Immer drafts cannot have computed properties",
    2: "This object has been frozen and should not be mutated",
    3: function (n) {
      return "Cannot use a proxy that has been revoked. Did you pass an object from inside an immer function to an async process? " + n;
    },
    4: "An immer producer returned a new value *and* modified its draft. Either return a new value *or* modify the draft.",
    5: "Immer forbids circular references",
    6: "The first or second argument to `produce` must be a function",
    7: "The third argument to `produce` must be a function or undefined",
    8: "First argument to `createDraft` must be a plain object, an array, or an immerable object",
    9: "First argument to `finishDraft` must be a draft returned by `createDraft`",
    10: "The given draft is already finalized",
    11: "Object.defineProperty() cannot be used on an Immer draft",
    12: "Object.setPrototypeOf() cannot be used on an Immer draft",
    13: "Immer only supports deleting array indices",
    14: "Immer only supports setting array indices and the 'length' property",
    15: function (n) {
      return "Cannot apply patch, path doesn't resolve: " + n;
    },
    16: 'Sets cannot have "replace" patches.',
    17: function (n) {
      return "Unsupported patch operation: " + n;
    },
    18: function (n) {
      return "The plugin for '" + n + "' has not been loaded into Immer. To enable the plugin, import and call `enable" + n + "()` when initializing your application.";
    },
    20: "Cannot use proxies if Proxy, Proxy.revocable or Reflect are not available",
    21: function (n) {
      return "produce can only be called on things that are draftable: plain objects, arrays, Map, Set or classes that are marked with '[immerable]: true'. Got '" + n + "'";
    },
    22: function (n) {
      return "'current' expects a draft, got: " + n;
    },
    23: function (n) {
      return "'original' expects a draft, got: " + n;
    },
    24: "Patching reserved attributes like __proto__, prototype and constructor is not allowed"
  },
  Z = "" + Object.prototype.constructor,
  nn = "undefined" != typeof Reflect && Reflect.ownKeys ? Reflect.ownKeys : void 0 !== Object.getOwnPropertySymbols ? function (n) {
    return Object.getOwnPropertyNames(n).concat(Object.getOwnPropertySymbols(n));
  } : Object.getOwnPropertyNames,
  tn = Object.getOwnPropertyDescriptors || function (n) {
    var t = {};
    return nn(n).forEach(function (r) {
      t[r] = Object.getOwnPropertyDescriptor(n, r);
    }), t;
  },
  rn = {},
  en = {
    get: function (n, t) {
      if (t === Q) return n;
      var e = p(n);
      if (!u(e, t)) return function (n, t, r) {
        var e,
          i = I(t, r);
        return i ? "value" in i ? i.value : null === (e = i.get) || void 0 === e ? void 0 : e.call(n.k) : void 0;
      }(n, e, t);
      var i = e[t];
      return n.I || !r(i) ? i : i === z(n.t, t) ? (E(n), n.o[t] = R(n.A.h, i, n)) : i;
    },
    has: function (n, t) {
      return t in p(n);
    },
    ownKeys: function (n) {
      return Reflect.ownKeys(p(n));
    },
    set: function (n, t, r) {
      var e = I(p(n), t);
      if (null == e ? void 0 : e.set) return e.set.call(n.k, r), !0;
      if (!n.P) {
        var i = z(p(n), t),
          o = null == i ? void 0 : i[Q];
        if (o && o.t === r) return n.o[t] = r, n.D[t] = !1, !0;
        if (c(r, i) && (void 0 !== r || u(n.t, t))) return !0;
        E(n), k(n);
      }
      return n.o[t] === r && "number" != typeof r && (void 0 !== r || t in n.o) || (n.o[t] = r, n.D[t] = !0, !0);
    },
    deleteProperty: function (n, t) {
      return void 0 !== z(n.t, t) || t in n.t ? (n.D[t] = !1, E(n), k(n)) : delete n.D[t], n.o && delete n.o[t], !0;
    },
    getOwnPropertyDescriptor: function (n, t) {
      var r = p(n),
        e = Reflect.getOwnPropertyDescriptor(r, t);
      return e ? {
        writable: !0,
        configurable: 1 !== n.i || "length" !== t,
        enumerable: e.enumerable,
        value: r[t]
      } : e;
    },
    defineProperty: function () {
      n(11);
    },
    getPrototypeOf: function (n) {
      return Object.getPrototypeOf(n.t);
    },
    setPrototypeOf: function () {
      n(12);
    }
  },
  on = {};
i(en, function (n, t) {
  on[n] = function () {
    return arguments[0] = arguments[0][0], t.apply(this, arguments);
  };
}), on.deleteProperty = function (t, r) {
  return "production" !== process.env.NODE_ENV && isNaN(parseInt(r)) && n(13), en.deleteProperty.call(this, t[0], r);
}, on.set = function (t, r, e) {
  return "production" !== process.env.NODE_ENV && "length" !== r && isNaN(parseInt(r)) && n(14), en.set.call(this, t[0], r, e, t[0]);
};
var un = function () {
    function e(t) {
      var e = this;
      this.g = B, this.F = !0, this.produce = function (t, i, o) {
        if ("function" == typeof t && "function" != typeof i) {
          var u = i;
          i = t;
          var a = e;
          return function (n) {
            var t = this;
            void 0 === n && (n = u);
            for (var r = arguments.length, e = Array(r > 1 ? r - 1 : 0), o = 1; o < r; o++) e[o - 1] = arguments[o];
            return a.produce(n, function (n) {
              var r;
              return (r = i).call.apply(r, [t, n].concat(e));
            });
          };
        }
        var f;
        if ("function" != typeof i && n(6), void 0 !== o && "function" != typeof o && n(7), r(t)) {
          var c = w(e),
            s = R(e, t, void 0),
            v = !0;
          try {
            f = i(s), v = !1;
          } finally {
            v ? O(c) : g(c);
          }
          return "undefined" != typeof Promise && f instanceof Promise ? f.then(function (n) {
            return j(c, o), P(n, c);
          }, function (n) {
            throw O(c), n;
          }) : (j(c, o), P(f, c));
        }
        if (!t || "object" != typeof t) {
          if ((f = i(t)) === H) return;
          return void 0 === f && (f = t), e.F && d(f, !0), f;
        }
        n(21, t);
      }, this.produceWithPatches = function (n, t) {
        return "function" == typeof n ? function (t) {
          for (var r = arguments.length, i = Array(r > 1 ? r - 1 : 0), o = 1; o < r; o++) i[o - 1] = arguments[o];
          return e.produceWithPatches(t, function (t) {
            return n.apply(void 0, [t].concat(i));
          });
        } : [e.produce(n, t, function (n, t) {
          r = n, i = t;
        }), r, i];
        var r, i;
      }, "boolean" == typeof (null == t ? void 0 : t.useProxies) && this.setUseProxies(t.useProxies), "boolean" == typeof (null == t ? void 0 : t.autoFreeze) && this.setAutoFreeze(t.autoFreeze);
    }
    var i = e.prototype;
    return i.createDraft = function (e) {
      r(e) || n(8), t(e) && (e = D(e));
      var i = w(this),
        o = R(this, e, void 0);
      return o[Q].C = !0, g(i), o;
    }, i.finishDraft = function (t, r) {
      var e = t && t[Q];
      "production" !== process.env.NODE_ENV && (e && e.C || n(9), e.I && n(10));
      var i = e.A;
      return j(i, r), P(void 0, i);
    }, i.setAutoFreeze = function (n) {
      this.F = n;
    }, i.setUseProxies = function (t) {
      t && !B && n(20), this.g = t;
    }, i.applyPatches = function (n, r) {
      var e;
      for (e = r.length - 1; e >= 0; e--) {
        var i = r[e];
        if (0 === i.path.length && "replace" === i.op) {
          n = i.value;
          break;
        }
      }
      e > -1 && (r = r.slice(e + 1));
      var o = b("Patches").$;
      return t(n) ? o(n, r) : this.produce(n, function (n) {
        return o(n, r);
      });
    }, e;
  }(),
  an = new un(),
  fn = an.produce,
  cn = an.produceWithPatches.bind(an),
  sn = an.setAutoFreeze.bind(an),
  vn = an.setUseProxies.bind(an),
  pn = an.applyPatches.bind(an),
  ln = an.createDraft.bind(an),
  dn = an.finishDraft.bind(an);
export default fn;
export { un as Immer, pn as applyPatches, K as castDraft, $ as castImmutable, ln as createDraft, D as current, J as enableAllPlugins, N as enableES5, C as enableMapSet, T as enablePatches, dn as finishDraft, d as freeze, L as immerable, t as isDraft, r as isDraftable, H as nothing, e as original, fn as produce, cn as produceWithPatches, sn as setAutoFreeze, vn as setUseProxies };
