import { ResourceConcept } from '@workbench/common/enums/resource-concept.enum';
import { clone as cloneMainFunction, MainFunction } from './main-function.model';

export class MfmModel {
  public conceptId: ResourceConcept = ResourceConcept.Undef;
  public comment: string;
  public exposed = false;
  public id: string = null;
  public label = '';
  public linkSourceId = '';
  public linkSourcePortName = '';
  public linkTargetId = '';
  public linkTargetPortName = '';
  public mainFunction: MainFunction = null;
  public parentId = '';
}

export const clone = (src: MfmModel): MfmModel =>
  Object.assign(new MfmModel(), {
    conceptId: src.conceptId,
    comment: src.comment,
    exposed: src.exposed,
    id: src.id,
    label: src.label,
    linkSourceId: src.linkSourceId,
    linkSourcePortName: src.linkSourcePortName,
    linkTargetId: src.linkTargetId,
    linkTargetPortName: src.linkTargetPortName,
    mainFunction: cloneMainFunction(src.mainFunction),
    parentId: src.parentId,
  });
