import { mxgraph } from '@workbench/dts/mxg';

export function paintShapeGlow(
  c: mxgraph.mxSvgCanvas2D,
  x: number,
  y: number,
  w: number,
  h: number,
  color: string,
): void {
  const extraRadius = 25;
  const r1 = Math.min(w, h) / 2;
  const r2 = r1 + extraRadius;
  const a1 = 0.6;
  const a2 = 0.05;
  const d = 4;
  const rs = (r2 - r1) / d;
  const as = (a1 - a2) / d;

  c.begin();
  c.setStrokeColor(color);
  c.setFillColor(color);

  c.setFillAlpha(a1);
  c.ellipse(x + (w + rs) / 2 - r1, y + (h + rs) / 2 - r1, 2 * r1 - rs, 2 * r1 - rs);
  c.fill();

  c.setStrokeWidth(rs);
  for (let i = 0, a = a1, r = r1; i < d; i++, r += rs, a -= as) {
    c.setStrokeAlpha(a);
    c.ellipse(x + w / 2 - r, y + h / 2 - r, 2 * r, 2 * r);
    c.stroke();
  }
  c.end();
}
