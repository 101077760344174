import { Injectable } from '@angular/core';
import { mxEvent, mxgraph } from '@workbench/dts/mxg';
import { CustomGraph } from '@workbench/mx-graph/extensions/custom-graph';
import { Observable, Subject } from 'rxjs';
import { debounceTime, filter, map } from 'rxjs/operators';

// Considered as a bridge between instantiated mxGraph and rest of framework.
// The intention is to have an injectable service that has access to the graph instance.
//
// Further idea: try to have action/effect-based manipulation over the graph
//
// NOTE: This service has only one consumer – GraphEffects.
// Another place it is used – mxGraph initialization, when the instance of Graph is passing to the service.
@Injectable({
  providedIn: 'root',
})
export class GraphService {
  public readonly mousePointer$: Observable<[number, number]>;

  private graph: CustomGraph;
  private readonly graphEventSource = new Subject<mxgraph.mxEventObject>();
  private readonly graphModelEventSource = new Subject<mxgraph.mxEventObject>();
  private readonly graphSelectionModelEventSource = new Subject<mxgraph.mxEventObject>();
  private readonly graphViewEventSource = new Subject<mxgraph.mxEventObject>();

  constructor() {
    // Service subscriptions
    this.mousePointer$ = this.addGraphListener(mxEvent.FIRE_MOUSE_EVENT).pipe(
      debounceTime(20),
      map(e => [e.properties.event.graphX, e.properties.event.graphY]),
    );
  }

  public getCells(): mxgraph.mxCell[] {
    return Object.values(this.graph.getModel().cells);
  }

  public getGraph(): CustomGraph {
    return this.graph;
  }

  public setGraph(graph: mxgraph.mxGraph): void {
    this.graph = graph as CustomGraph;
    // Subscribe to all mxGraph events (graph, view, model)
    this.graph.addListener(null, (sender: mxgraph.mxGraph, event: mxgraph.mxEventObject) => {
      this.graphEventSource.next(event);
    });
    this.graph
      .getModel()
      .addListener(null, (sender: mxgraph.mxGraph, event: mxgraph.mxEventObject) => {
        this.graphModelEventSource.next(event);
      });
    this.graph
      .getSelectionModel()
      .addListener(null, (sender: mxgraph.mxGraph, event: mxgraph.mxEventObject) => {
        this.graphSelectionModelEventSource.next(event);
      });
    this.graph
      .getView()
      .addListener(null, (sender: mxgraph.mxGraph, event: mxgraph.mxEventObject) => {
        this.graphViewEventSource.next(event);
      });
  }

  /**
   * Subscribe to the mxGraph event stream
   *
   * @param events event(s) name
   * @returns a stream that emits given mxGraph event
   */
  public addGraphListener(...events: string[]): Observable<mxgraph.mxEventObject> {
    return this.graphEventSource.pipe(filter(event => events.includes(event.getName())));
  }

  /**
   * Subscribe to the mxGraphModel event stream
   *
   * @param events event(s) name
   * @returns a stream that emits given mxGraph event
   */
  public addGraphModelListener(...events: string[]): Observable<mxgraph.mxEventObject> {
    return this.graphModelEventSource.pipe(filter(event => events.includes(event.getName())));
  }

  /**
   * Subscribe to the mxGraphSelectionModel event stream
   *
   * @param events event(s) name
   * @returns a stream that emits given mxGraph event
   */
  public addGraphSelectionModelListener(...events: string[]): Observable<mxgraph.mxEventObject> {
    return this.graphSelectionModelEventSource.pipe(
      filter(event => events.includes(event.getName())),
    );
  }

  /**
   * Subscribe to the mxGraphView event stream
   *
   * @param events event(s) name
   * @returns a stream that emits given mxGraph event
   */
  public addGraphViewListener(...events: string[]): Observable<mxgraph.mxEventObject> {
    return this.graphViewEventSource.pipe(filter(event => events.includes(event.getName())));
  }
}
