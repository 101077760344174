export function generateUUID() {
  let d = new Date().getTime();
  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (d + Math.random() * 16) % 16 | 0;

    d = Math.floor(d / 16);

    return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });

  return uuid;
}

export const getStyleObject = (style: string) => {
  const result = {};

  if (style && style.length > 0) {
    style.split(';').forEach(function (x) {
      const arr = x.split('=');

      arr[1] && (result[arr[0]] = arr[1]);
    });
  }

  return result;
};
