import {
  isOfflineProcessVariable,
  isVirtualProcessVariable,
  processVariableValue,
} from '@workbench/common/types/process-variable-value.type';
import { it } from '@workbench/common/utils/logical-utility';
import { getNotEmpty } from '@workbench/common/utils/string-util';
import { mxConstants, mxgraph, mxHexagon, mxPoint, mxUtils } from '@workbench/dts/mxg';
import {
  styleGlowColor,
  styleHighlightColor,
  styleOutlineColor,
  styleProcessVariable,
  styleReliefSystem,
  styleSensorState,
} from '../extensions/custom-style';
import { processVariableStyleColor } from './colors';
import { paintShapeGlow } from './shape-glow';

export class StorageShape extends mxHexagon {
  public readonly constraints: mxgraph.mxConnectionConstraint[] = [];

  public paintVertexShape(
    c: mxgraph.mxSvgCanvas2D,
    x: number,
    y: number,
    w: number,
    h: number,
  ): void {
    const padding = 2;
    const glowColor = this.style[styleGlowColor()];
    const outlineColor = this.style[styleOutlineColor()];
    const processVariable = processVariableValue(this.style[styleProcessVariable()]);
    const processVariableColor = processVariableStyleColor(processVariable);
    const reliefSystem = this.style[styleReliefSystem()] === 1 ? 'RS' : '';
    const sensorState = this.style[styleSensorState()] ?? '';

    if (glowColor) {
      paintShapeGlow(c, x, y, w, h, glowColor);
    }

    if (outlineColor) {
      const arcSize =
        mxUtils.getValue(this.style, mxConstants.STYLE_ARCSIZE, mxConstants.LINE_ARCSIZE) / 2;

      c.setFillColor(outlineColor);
      c.setStrokeColor(outlineColor);
      c.setStrokeWidth(16);
      c.begin();
      // prettier-ignore
      this.addPoints(c, [new mxPoint(x + 0.25 * w, y), new mxPoint(x + 0.75 * w, y), new mxPoint(x + w, y + 0.5 * h), new mxPoint(x + 0.75 * w, y + h), new mxPoint(x + 0.25 * w, y + h), new mxPoint(x, y + 0.5 * h)], this.isRounded, arcSize, true, null, null);
      c.fillAndStroke();
    }

    if (sensorState !== '' || reliefSystem !== '') {
      if (sensorState !== '' && reliefSystem !== '') {
        // prettier-ignore
        c.text(x + w / 2, y + h, w, h, `${sensorState}\u2022${reliefSystem}`, mxConstants.ALIGN_CENTER, mxConstants.ALIGN_CENTER, null, null, null, null, null, null);
      } else {
        // prettier-ignore
        c.text(x + w / 2, y + h, w, h, getNotEmpty(sensorState, reliefSystem), mxConstants.ALIGN_CENTER, mxConstants.ALIGN_CENTER, null, null, null, null, null, null);
      }
    }

    c.setFillAlpha(1);
    // prettier-ignore
    c.setFillColor(this.style[styleHighlightColor()] ?? processVariableColor ?? this.style[mxConstants.STYLE_FILLCOLOR]);
    c.setStrokeAlpha(1);
    c.setStrokeColor(this.style[mxConstants.STYLE_STROKECOLOR]);
    c.setStrokeWidth(this.style[mxConstants.STYLE_STROKEWIDTH]);

    super.paintVertexShape(c, x, y + padding / 2, w, h - padding);

    if (it(isVirtualProcessVariable(processVariable))) {
      const r = 12;

      c.setFillColor(processVariableColor);
      c.begin();
      c.ellipse(w - 4, h - r, r, r);
      c.fillAndStroke();
      c.end();
    }
    if (it(isOfflineProcessVariable(processVariable))) {
      const r = 16;

      c.setFillColor('#FFFFFF');
      c.begin();
      c.ellipse(w / 2 - r / 2, h / 2 - r / 2 - 1, r, r);
      c.fill();
      c.end();
    }
  }
}
