import { createAction, props } from '@ngrx/store';
import { Option } from '@workbench/common/models/option';

export const fetchObjectRoleOptions = createAction('[Options/API] Fetch Object Role Options');
export const fetchObjectRoleOptionsSuccess = createAction(
  '[Options/API] Fetch Object Role Options Success',
  props<{ response: Option[] }>(),
);
export const fetchObjectRoleOptionsFault = createAction(
  '[Options/API] Fetch Object Role Options Fault',
  props<{ error: string }>(),
);

export const setObjectRoleOptions = createAction(
  '[Options] Set Object Role Options',
  props<{ options: Option[] }>(),
);
