import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ReasoningState } from '.';

const selectFeatureState = createFeatureSelector<ReasoningState>('reasoning');

export const selectCauses = createSelector(selectFeatureState, s => s.causes);
export const selectConsequences = createSelector(selectFeatureState, s => s.consequences);
export const selectCurrentPathIndex = createSelector(selectFeatureState, s => s.selectedItem);
export const selectFaultCase = createSelector(selectFeatureState, s => s.fault);
export const selectReasoningDisplaySection = createSelector(selectFeatureState, s => s.section);
export const selectReasoningItems = createSelector(selectFeatureState, s => {
  // prettier-ignored
  switch (s.section) {
    case 'causes':
      return s.causes.map((x, index) => ({ ...x.rootCause, uid: index }));
    case 'consequences':
      return s.consequences.map((x, index) => ({ ...x.endConsequence, uid: index }));
    default:
      return [];
  }
});

export const selectCurrentPath = createSelector(
  selectCauses,
  selectConsequences,
  selectReasoningDisplaySection,
  selectCurrentPathIndex,
  (causes, consequences, section, index) => {
    // prettier-ignore
    switch (section) {
      case 'causes': return causes.find(({ uid }) => uid === index) ?? null;
      case 'consequences': return consequences.find(({ uid }) => uid === index) ?? null;
      default: return null;
    }
  },
);

export const selectCurrentItem = createSelector(
  selectCurrentPath,
  selectReasoningDisplaySection,
  (path, section) => {
    // prettier-ignore
    switch (section) {
      case 'causes': return path.rootCause ?? null;
      case 'consequences': return path.endConsequence ?? null;
      default: return null;
    }
  },
);
