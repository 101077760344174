export const styleActuator = (): 'wbActuator' => 'wbActuator';
export const styleBorderColor = (): 'wbBorderColor' => 'wbBorderColor';
export const styleBorderWidth = (): 'wbBorderWidth' => 'wbBorderWidth';
export const styleExposed = (): 'wbExposed' => 'wbExposed';
export const styleGlowColor = (): 'wbGlowColor' => 'wbGlowColor';
export const styleHazardLevel = (): 'wbHazardLevel' => 'wbHazardLevel';
export const styleHighlightColor = (): 'wbHighlightColor' => 'wbHighlightColor';
export const styleLogicalGateValue = (): 'wbLogicalGateValue' => 'wbLogicalGateValue';
export const styleObjectiveCategory = (): 'wbObjectiveCategory' => 'wbObjectiveCategory';
export const styleOutlineColor = (): 'wbOutlineColor' => 'wbOutlineColor';
export const styleProcessVariable = (): 'wbProcessVariable' => 'wbProcessVariable';
export const styleReliefSystem = (): 'wbReliefSystem' => 'wbReliefSystem';
export const styleSensorState = (): 'wbSensorState' => 'wbSensorState';
export const styleSubModelPort = (): 'wbSubModelPort' => 'wbSubModelPort';
