import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { startWith } from 'rxjs/operators';

const defaultTitle = (): string => 'Kairos Workbench';

@Injectable()
export class TitleService {
  public readonly title$: Observable<string>;

  private readonly titleSource = new Subject<string>();

  constructor() {
    this.title$ = this.titleSource.asObservable().pipe(startWith(defaultTitle()));
  }

  public updateTitle(title: string): void {
    this.titleSource.next(title);
  }

  public resetTitle(): void {
    this.titleSource.next(defaultTitle());
  }
}
