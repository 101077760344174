import { actuatorKind, ActuatorKindStyle } from '@workbench/common/types/actuator-kind.type';
import {
  isOfflineProcessVariable,
  isVirtualProcessVariable,
  processVariableValue,
} from '@workbench/common/types/process-variable-value.type';
import { it } from '@workbench/common/utils/logical-utility';
import {
  mxConnectionConstraint,
  mxConstants,
  mxgraph,
  mxPoint,
  mxRhombus,
} from '@workbench/dts/mxg';
import {
  styleActuator,
  styleGlowColor,
  styleHighlightColor,
  styleOutlineColor,
  styleProcessVariable,
  styleSensorState,
} from '../extensions/custom-style';
import { actuatorColor, processVariableStyleColor } from './colors';
import { paintShapeGlow } from './shape-glow';

export class TransportShape extends mxRhombus {
  public readonly constraints: mxgraph.mxConnectionConstraint[] = [
    new mxConnectionConstraint(new mxPoint(0, 0.5), true, 'in'),
    new mxConnectionConstraint(new mxPoint(1, 0.5), true, 'out'),
  ];

  public paintVertexShape(
    c: mxgraph.mxSvgCanvas2D,
    x: number,
    y: number,
    w: number,
    h: number,
  ): void {
    const glowColor = this.style[styleGlowColor()];
    const outlineColor = this.style[styleOutlineColor()];
    const actuatorValue = actuatorKind(this.style[styleActuator()]);
    const processVariable = processVariableValue(this.style[styleProcessVariable()]);
    const processVariableColor = processVariableStyleColor(processVariable);
    const sensorState = this.style[styleSensorState()] ?? '';

    if (glowColor) {
      paintShapeGlow(c, x, y, w, h, glowColor);
    }

    if (outlineColor) {
      const d = 12;

      c.setFillColor(outlineColor);
      c.setFillAlpha(1);
      c.setStrokeAlpha(0);
      super.paintVertexShape(c, x - d, y - d, w + 2 * d, h + 2 * d);
    }

    c.setFillAlpha(1);
    c.setStrokeAlpha(1);
    c.setStrokeWidth(2);
    // prettier-ignore
    c.setFillColor(this.style[styleHighlightColor()] ?? processVariableColor ?? this.style[mxConstants.STYLE_FILLCOLOR]);
    c.setStrokeColor(this.style[mxConstants.STYLE_STROKECOLOR]);

    super.paintVertexShape(c, x, y, w, h);

    c.translate(x, y);

    if (it(isVirtualProcessVariable(processVariable))) {
      const d = 12;

      c.setFillColor(processVariableColor);
      c.begin();
      c.ellipse(w - d + 3, h - d + 3, d, d);
      c.fillAndStroke();
      c.end();
    }
    if (it(isOfflineProcessVariable(processVariable))) {
      const d = 16;

      c.setFillColor('#FFFFFF');
      c.begin();
      c.ellipse(w / 2 - d / 2, h / 2 - d / 2, d, d);
      c.fill();
      c.end();
    }
    if (actuatorValue !== ActuatorKindStyle.None) {
      c.setFillColor(actuatorColor());
      super.paintVertexShape(c, 0, 0, w, h);

      if (actuatorValue === ActuatorKindStyle.Manual) {
        const d = 16;

        c.setFillColor('#FFFFFF');
        c.begin();
        c.ellipse(w / 2 - d / 2, h / 2 - d / 2, d, d);
        c.fill();
        c.end();
      }
      if (actuatorValue === ActuatorKindStyle.Provisional) {
        c.setFillColor('#FFFFFF');
        c.begin();
        c.moveTo(0, w / 2);
        c.lineTo(w / 2, 0);
        c.lineTo(w, w / 2);
        c.lineTo(0, w / 2);
        c.fill();
      }
    }

    // Draw arrow
    c.setStrokeColor(this.style[mxConstants.STYLE_STROKECOLOR]);
    c.setStrokeWidth(2);
    c.begin();
    c.moveTo(0.2 * w, 0.5 * h);
    c.lineTo(0.8 * w, 0.5 * h);
    c.moveTo(0.78 * w, 0.5 * h);
    c.lineTo(0.58 * w, 0.3 * h);
    c.moveTo(0.78 * w, 0.5 * h);
    c.lineTo(0.58 * w, 0.7 * h);
    c.stroke();

    if (sensorState !== '') {
      const rotation = this.style[mxConstants.STYLE_ROTATION] ?? 0;
      // Always keep the text at the bottom of the shape
      const tp = c.rotatePoint(w / 2, h + 7, -rotation, w / 2, h / 2);

      // prettier-ignore
      c.text(tp.x, tp.y, 0, 0, sensorState, mxConstants.ALIGN_CENTER, mxConstants.ALIGN_MIDDLE, null, null, null, null, -rotation, null);
    }
  }

  public getTextRotation(): number {
    // text label is always above model, so text rotation is prevented
    return 0;
  }
}
